.manageUserContainer {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #1c1c29;
  color: #f8f0c6;
  border-radius: 10px;
  border: 2px solid #6d4e27;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

h1 {
  text-align: center;
  color: #ffd700;
}

h2, h3 {
  margin: 10px 0;
}

.formGroup {
  margin-bottom: 15px;
}

input[type="number"], input[type="text"], select {
  border: 1px solid #6d4e27;
  border-radius: 5px;
  background-color: #292936;
  color: #f8f0c6;
}

button {
  padding: 10px 20px;
  background-color: #6d4e27;
  border: none;
  border-radius: 5px;
  color: #f8f0c6;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #8a6a45;
}

.successMessage {
  color: #28a745;
  text-align: center;
}

.errorMessage {
  color: #ff6347;
  text-align: center;
}

.searchResults {
  background-color: #292936;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

.userManagementSection {
  margin-top: 20px;
}

.selectUserButton {
  margin-left: 10px;
  background-color: #6d4e27;
  color: #f8f0c6;
  border-radius: 5px;
  padding: 5px 10px;
}

.selectUserButton:hover {
  background-color: #8a6a45;
}

.updateButton, .removeButton, .clearDeathButton {
  margin-top: 5px;
  background-color: #6d4e27;
}

.updateButton:hover, .removeButton:hover, .clearDeathButton:hover {
  background-color: #8a6a45;
}
