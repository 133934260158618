.battleHistoryContainer {
  padding: 30px;
  background-color: #1c1c29;
  border: 2px solid #6d4e27;
  border-radius: 12px;
  max-width: 900px;
  margin: 20px auto;
  color: #f8f0c6;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.title {
  text-align: center;
  font-size: 2rem;
  font-family: 'Cinzel', serif;
  color: #ffd700;
  margin-bottom: 20px;
}

.loading {
  text-align: center;
  color: #dcdcdc;
  font-size: 1.1rem;
}

.battleHistoryTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-size: 1rem;
}

.battleHistoryTable th,
.battleHistoryTable td {
  border: 1px solid #666;
  padding: 10px;
  text-align: center;
  font-family: 'Cinzel', serif;
}

.battleHistoryTable th {
  background-color: #292936;
  color: #ffd700;
  font-size: 1.1rem;
}

.battleHistoryTable tr:nth-child(even) {
  background-color: #2f2f3e;
}

.battleHistoryTable tr:hover {
  background-color: #3b3f53;
}

.statusWin {
  color: #28a745;
}

.statusLoss {
  color: #d9534f;
}

.statusUnknown {
  color: #6c757d;
}

.viewButton {
  padding: 5px 10px;
  background-color: #6d4e27;
  color: #f8f0c6;
  border: none;
  border-radius: 5px;
  font-family: 'Cinzel', serif;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
}

.viewButton:hover {
  background-color: #8a6a45;
}

.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.pagination button {
  padding: 10px 20px;
  background-color: #6d4e27;
  color: #f8f0c6;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Cinzel', serif;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #8a6a45;
}

.pagination button:disabled {
  background-color: #444;
  cursor: not-allowed;
  opacity: 0.6;
}
