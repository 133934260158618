.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modalContent {
    background: #1c1c29;
    padding: 20px;
    border-radius: 8px;
    width: 750px;
    display: flex;
    flex-direction: column;
    border: 2px solid;
    border-color: #6d4e27;
  }
  
  .modalContent input,
  .modalContent textarea {
    margin: 10px 0;
    padding: 8px;
  }
  
  .modalContent button {
    margin: 5px 0;
  }
  