@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;700&family=MedievalSharp&display=swap');

.tournamentBracket {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  padding: 10px;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
}

.tournamentBracket::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

.round {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px;
  background-color: #1c1c29;
  padding: 15px;
  border-radius: 8px;
  border: 2px solid #6d4e27;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.roundHeader {
  font-family: 'MedievalSharp', serif;
  cursor: pointer;
  color: #ffd700;
  user-select: none;
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.matches {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.match {
  background-color: #333;
  border: 2px solid #ffd700;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  position: relative;
  min-height: 80px;
  color: #f8f0c6;
}

.team {
  color: #f8f0c6;
  font-size: 14px;
  padding: 5px;
  width: 90%;
}

.winnerTeam {
  background-color: rgba(50, 205, 50, 0.2);
  border-radius: 5px;
  padding: 5px;
}

.vs {
  color: #ffd700;
  font-weight: bold;
  margin: 5px 0;
}

.winner {
  margin-top: 5px;
  color: #32cd32;
}

.viewBattleReportLink {
  display: block;
  margin-top: 5px;
  color: #00aaff;
  text-decoration: none;
  transition: color 0.3s;
}

.viewBattleReportLink:hover {
  color: #66ccff;
}

.finalLabel {
  background-color: rgb(185, 158, 2);
  color: black;
  border-radius: 8px;
  padding: 5px;
  margin-bottom: 5px;
  font-weight: bold;
}

.thirdPlaceLabel {
  background-color: rgb(185, 106, 2);
  color: black;
  border-radius: 8px;
  padding: 5px;
  margin-bottom: 5px;
  font-weight: bold;
}

/* Responsive Design */
@media (max-width: 768px) {
  .round {
    min-width: 100%;
  }
}
