.homeContainer {
  padding: 30px;
  background-color: #1c1c29;
  border: 2px solid #6d4e27;
  border-radius: 12px;
  color: #f8f0c6;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  text-align: center;
}

.welcomeTitle {
  font-family: 'Cinzel', serif;
  color: #ffd700;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.bookContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.createMercenaryButton {
  display: inline-block;
  margin-top: 15px;
  color: #f8f0c6;
  background-color: #6d4e27;
  text-decoration: none;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  border: 2px solid #8a6a45;
}

.createMercenaryButton:hover {
  background-color: #8a6a45;
}
