:root {
    --background-dark: #1c1c29;
    --text-main: #f8f0c6;
    --text-gold: #ffd700;
    --border-color: #6d4e27;
    --card-bg: #2f2f3e;
    --highlight-text: #dcdcdc;
    --button-hover-bg: #8a6a45;
  }
  
  .worldEditorContainer {
    color: var(--text-main);
    padding: 20px;
    margin: 0 auto;
    background-color: var(--background-dark);
    border-radius: 12px;
    border: 2px solid var(--border-color);
    max-width: 1200px;
  }
  
  .worldEditorTitle {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 20px;
    color: var(--text-gold);
  }
  
  .tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
  
  .tabButton {
    background-color: var(--card-bg);
    color: var(--text-gold);
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    margin: 5px;
    transition: background-color 0.3s ease;
  }
  
  .tabButton:hover {
    background-color: var(--button-hover-bg);
  }
  
  .activeTab {
    background-color: var(--border-color);
    color: var(--text-main);
  }
  
  .tabContent {
    background-color: var(--card-bg);
    border-radius: 8px;
    padding: 20px;
    border: 1px solid var(--border-color);
    color: var(--text-main);
  }
  
  /* Form Elements Styling */
  .formGroup {
    margin-bottom: 15px;
  }
  
  .formGroup label {
    color: var(--highlight-text);
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
  }
  
  .formGroup input,
  .formGroup select,
  .formGroup textarea {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #666;
    background-color: var(--background-dark);
    color: var(--text-main);
  }
  
  .formGroup input[type="checkbox"] {
    width: auto;
    margin-right: 10px;
  }
  
  .saveButton {
    background-color: var(--border-color);
    color: var(--text-main);
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .saveButton:hover {
    background-color: var(--button-hover-bg);
  }
  
  /* Message Styling */
  .message {
    margin-top: 10px;
    padding: 10px;
    border-radius: 5px;
    background-color: var(--card-bg);
    color: var(--text-gold);
    text-align: center;
  }
  
  /* Responsive Styling */
  @media (max-width: 768px) {
    .worldEditorTitle {
      font-size: 2rem;
    }
  
    .tabButton {
      margin-bottom: 10px;
    }
  
    .formGroup input,
    .formGroup select,
    .formGroup textarea {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .tabs {
      flex-direction: column;
      align-items: center;
    }
  
    .tabButton {
      width: 100%;
    }
  }
  