.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    position: relative;
    background: #1c1c29;
    border: 2px solid #6d4e27;
    border-radius: 10px;
    width: 400px;
    max-width: 90%;
    padding: 20px;
    text-align: center;
    font-family: 'Cinzel', serif;
}

.modalTitle {
    color: #ffd700;
    margin-bottom: 10px;
    font-size: 1.5rem;
}

.modalMessage {
    color: #f8f0c6;
    margin-bottom: 20px;
}

.modalActions {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.cancelButton,
.confirmButton {
    background: #6d4e27;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    color: #ffd700;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.cancelButton:hover {
    background: #8a6a45;
}

.confirmButton:hover {
    background: #8a6a45;
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    font-weight: bold;
    color: #850101;
    cursor: pointer;
    transition: color 0.3s ease;
    padding: 0;
    margin: 0;
}

.closeButton:hover {
    color: #ad0000;
    transform: scale(1.1);
    background: transparent;
}
