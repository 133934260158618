.levelUpContainer {
  padding: 20px;
  background-color: #1c1c29;
  color: #f8f0c6;
  border-radius: 8px;
  width: 100%;
  max-width: 800px;
  margin: auto;
  border: 2px solid #6d4e27;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.skillsTable {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  align-items: center;
  margin-top: 10px;
}

.skillsHeader {
  display: contents;
}

.skillsHeader span {
  font-weight: bold;
  background-color: #292936;
  padding: 10px;
  text-align: center;
  border-bottom: 2px solid #1e1e2f;
  color: #ffd700;
}

.skillRow {
  display: contents;
}

.skillRow:nth-child(even) {
  background-color: #2f2f3e;
}

.skillRow:nth-child(odd) {
  background-color: #1a1a1a;
}

.skillInput {
  width: 100%;
  max-width: 60px;
  padding: 5px;
  text-align: center;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: #333;
  color: var(--main-text);
}

.skillInput[type="number"]::-webkit-outer-spin-button,
.skillInput[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: auto;
  appearance: auto;
}

.skillInput[type="number"] {
  appearance: textfield;
}

.saveButton {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #6d4e27;
  color: #f8f0c6;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Cinzel', serif;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.saveButton:hover {
  background-color: #8a6a45;
}

.error {
  color: #ff4d4d;
  margin-top: 10px;
}
