.mercenariesContainer {
    padding: 20px;
    background-color: #1c1c29;
    border: 2px solid #6d4e27;
    border-radius: 12px;
    color: #f8f0c6;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    max-width: 800px;
    margin: auto;
  }
  
  .title {
    text-align: center;
    font-size: 2rem;
    font-family: 'Cinzel', serif;
    color: #ffd700;
    margin-bottom: 20px;
  }
  
  .mercenaryTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    font-size: 0.9rem;
  }
  
  .mercenaryTable th,
  .mercenaryTable td {
    border: 1px solid #666;
    padding: 8px;
    text-align: center;
    font-family: 'Cinzel', serif;
  }
  
  .mercenaryTable th {
    background-color: #292936;
    color: #ffd700;
    font-size: 1rem;
  }
  
  .mercenaryTable td {
    background-color: #2f2f3e;
  }
  
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  .actionButton,
  .deleteButton {
    padding: 6px 12px;
    font-family: 'Cinzel', serif;
    font-weight: bold;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    margin: 0 4px;
  }
  
  .actionButton {
    background-color: #6d4e27;
    color: #f8f0c6;
  }
  
  .actionButton:hover {
    background-color: #8a6a45;
  }
  
  .deleteButton {
    background-color: #8a4545;
    color: #f8f0c6;
  }
  
  .deleteButton:hover {
    background-color: #a85353;
  }
  
  .noMercenariesMessage {
    text-align: center;
    color: #dcdcdc;
    font-size: 1.1rem;
    margin-top: 20px;
  }
  
  .creationSection {
    text-align: center;
    margin-top: 20px;
  }
  
  .createButton {
    padding: 8px 16px;
    background-color: #6d4e27;
    color: #f8f0c6;
    border: none;
    border-radius: 5px;
    font-family: 'Cinzel', serif;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .createButton:disabled {
    background-color: #444;
    cursor: not-allowed;
  }
  
  .creationMessage {
    margin-top: 10px;
    color: #dcdcdc;
    font-size: 1rem;
    font-family: 'Cinzel', serif;
  }
  
  .confirmOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .confirmModal {
    background-color: #1c1c29;
    padding: 20px;
    border: 2px solid #6d4e27;
    border-radius: 8px;
    text-align: center;
    color: #f8f0c6;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  }
  
  .modalButtons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 15px;
  }
  
  .confirmButton {
    background-color: #8a4545;
    color: #f8f0c6;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    font-family: 'Cinzel', serif;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .confirmButton:hover {
    background-color: #a85353;
  }
  
  .cancelButton {
    background-color: #444;
    color: #f8f0c6;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    font-family: 'Cinzel', serif;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cancelButton:hover {
    background-color: #666;
  }
  