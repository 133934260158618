:root {
  --background-dark: #1c1c29;
  --background-medium: #33394d;
  --text-light: #f8f0c6;
  --text-medium: #e0e0e0;
  --highlight: #ffd700;
  --border-color: #6d4e27;
  --link-color: #ffd700;
  --link-hover-color: #f8f0c6;
  --button-primary-bg: #6d4e27;
  --button-primary-hover-bg: #8a6a45;
}

.mercenaryBar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 18vh;
  padding: 10px;
  background-color: var(--background-dark);
  color: var(--text-medium);
  border-left: 2px solid var(--border-color);
  height: auto;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  height: 97%;
}

.mercenaryAvatar {
  text-align: center;
  margin-bottom: 20px;
  position: relative;
}

.mercenaryAvatar img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-bottom: 10px;
  border: 4px solid var(--highlight-text);
  box-shadow: 0 0 15px #ffdd57;
}

.mercenaryAvatar h2 {
  font-size: 1.5em;
  margin: 0;
  color: var(--text-light);
}

.mercenaryAvatar p {
  margin: 0;
  font-size: 1em;
  color: var(--text-light);
}

.battleNotification {
  position: absolute;
  top: 50%;
  left: -270px;
  transform: translateY(-50%);
  background-color: var(--background-dark);
  border: 1px solid var(--border-color);
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  width: 250px;
  z-index: 1000;
  transition: opacity 0.3s ease-in-out;
  font-family: Arial, sans-serif;
}

.battleNotification p {
  margin: 0 0 10px;
  color: var(--text-light);
  font-size: 1em;
}

.battleNotification button {
  display: block;
  width: 100%;
  padding: 8px 12px;
  margin: 5px 0;
  border: none;
  background-color: var(--button-primary-bg);
  color: var(--text-light);
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.battleNotification button:last-child {
  background-color: var(--button-primary-hover-bg);
}

.battleNotification button:hover {
  background-color: var(--button-primary-hover-bg);
}

.progressBar {
  width: 100%;
  height: 20px;
  background-color: #33394d;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  margin: 5px;
  border: 1px solid #6f6f6f;
}

.fill {
  height: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
}

.hpBar .fill {
  background-color: #8d0a06;
}

.epBar .fill {
  background-color: #5d2e63;
}

.regenBar .fill {
  background-color: #bf7b0e;
}

.progressBar span {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
  line-height: 20px;
  color: #fff;
  font-weight: bold;
  pointer-events: none;
  z-index: 1;
}

.levelUpButton {
  background-color: rgb(5, 99, 5);
  color: var(--text-light);
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  margin-top: 10px;
  width: 100%;
  transition: background-color 0.3s;
}

.levelUpButton:hover {
  background-color: rgb(9, 82, 9);
}

.mercenaryLinks, .accountLinks {
  margin-top: 20px;
}

.mercenaryLinks h3, .accountLinks h3 {
  margin-bottom: 10px;
  font-size: 1.1em;
  color: var(--text-light);
}

.mercenaryLinks ul, .accountLinks ul {
  list-style-type: none;
  padding: 0;
}

.mercenaryLinks ul li, .accountLinks ul li {
  margin: 5px 0;
}

.mercenaryLinks ul li a, .accountLinks ul li a {
  text-decoration: none;
  color: var(--link-color);
  font-size: 1em;
}

.mercenaryLinks ul li a:hover, .accountLinks ul li a:hover {
  text-decoration: underline;
  color: var(--link-hover-color);
}

.spacer {
  flex-grow: 1;
}

.labelValuePair {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}

.notificationBadge {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 0.1em 0.4em;
  font-size: 0.8em;
  margin-left: 0.5em;
}

.labelValuePair {
  color: var(--highlight-text);
  font-size: 14px;
  font-weight: 700;
  font-family: Cinzel, serif;
  color: #f0f0f0;
  margin: 0;
  align-items: center;
}

.contractIndicator {
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s, color 0.3s;
}

.contractIndicator.enabled {
  background-color: #6d4e27;
  color: #f8f0c6;
}

.contractIndicator.disabled {
  background-color: #8d0a06;
  color: #ffffff;
}

.contractIndicator:hover {
  opacity: 0.8;
}