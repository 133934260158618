/* General Styling */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  color: #e0e0e0;
  
  /* Background properties */
  background-color: rgba(28, 28, 41, 0.5);
  background-image: url('https://njordheim.com/img/ah_texture.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

.layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-height: 100vh;
}

.main-content {
  display: flex;
  flex: 1;
  overflow-y: auto;
}

.content-area {
  flex: 1;
  padding: 20px;
  color: #e0e0e0;
  background-color: rgba(28, 28, 41, 0.507);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  max-height: calc(100vh - 40px);
}

.content-area::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.content-area {
  scrollbar-width: none;
}

