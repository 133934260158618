/* Spinner.module.css */
.spinner {
    width: 40px;
    height: 40px;
    position: relative;
    margin: auto;
  }
  
  .doubleBounce1, 
  .doubleBounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #112179;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    animation: sk-bounce 2s infinite ease-in-out;
  }
  
  .doubleBounce2 {
    animation-delay: -1s;
  }
  
  @keyframes sk-bounce {
    0%, 100% {
      transform: scale(0);
    } 
    50% {
      transform: scale(1);
    }
  }
  