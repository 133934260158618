@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;700&family=MedievalSharp&display=swap');

.createLobbyContainer {
  padding: 25px;
  background-color: #1c1c29;
  color: #f8f0c6;
  border-radius: 12px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  border: 2px solid #6d4e27;
  font-family: 'Cinzel', serif;
}

.title {
  font-family: 'MedievalSharp', serif;
  text-align: center;
  font-size: 2.5rem;
  color: #ffd700;
}

.formGroup {
  margin-bottom: 15px;
}

.formGroup label {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #ffd700;
}

.formGroup input[type="text"],
.formGroup select {
  width: 100%;
  padding: 10px;
  border: 1px solid #6d4e27;
  border-radius: 5px;
  background-color: #33394d;
  color: #f8f0c6;
  font-size: 1.1rem;
  font-family: 'Cinzel', serif;
}

.formGroup input[type="checkbox"] {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0;
  accent-color: #6d4e27;
}

.errorMessage {
  color: #ff4c4c;
  margin-bottom: 15px;
  text-align: center;
}

.createLobbyButton {
  width: 100%;
  padding: 15px;
  background-color: #6d4e27;
  color: #f8f0c6;
  border: none;
  border-radius: 5px;
  font-size: 1.1rem;
  cursor: pointer;
  margin-top: 10px;
  font-weight: bold;
  text-transform: uppercase;
  transition: background-color 0.3s;
  font-family: 'Cinzel', serif;
}

.createLobbyButton:hover {
  background-color: #8a6a45;
}

@media (max-width: 600px) {
  .createLobbyContainer {
    padding: 20px;
  }

  .createLobbyButton {
    font-size: 1rem;
  }

  .formGroup input[type="text"],
  .formGroup select {
    padding: 8px;
  }
}

.checkboxContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.checkboxContainer label {
  font-weight: bold;
  color: #ffd700;
  display: flex;
  align-items: center;
}

.checkboxContainer input[type="checkbox"] {
  accent-color: #6d4e27;
  width: 20px;
  height: 20px;
}