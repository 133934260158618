:root {
  --background-dark: #1c1c29;
  --text-gold: #ffd700;
  --text-light: #f8f0c6;
  --button-primary-bg: #6d4e27;
  --button-primary-hover-bg: #8a6a45;
  --border-color: #6d4e27;
  --card-bg: #2f2f3e;
  --card-hover-shadow: 0 0 15px #ffd700;
  --highlight-text: #ffdd57;
}

.createTournamentContainer {
  margin: 0 auto;
  padding: 20px;
  background-color: var(--background-dark);
  color: var(--text-gold);
  border-radius: 15px;
  border: 2px solid var(--border-color);
  font-family: 'Cinzel', serif;
  overflow-y: auto;
}

.createTournamentContainer h2 {
  text-align: center;
  color: var(--text-gold);
  margin-bottom: 20px;
}

.createTournamentForm label {
  display: block;
  margin-bottom: 15px;
  font-weight: bold;
  color: var(--text-light);
}

.inputField,
.selectField {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border-radius: 5px;
  background-color: var(--card-bg);
  color: var(--text-gold);
  border: 1px solid var(--border-color);
  outline: none;
}

.createTournamentBtn {
  width: 100%;
  padding: 10px;
  margin-top: 15px;
  background-color: var(--button-primary-bg);
  color: var(--text-light);
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  font-size: 1rem;
}

.createTournamentBtn:hover {
  background-color: var(--button-primary-hover-bg);
  box-shadow: var(--card-hover-shadow);
}

.successMessage {
  margin-top: 15px;
  color: var(--text-gold);
  text-align: center;
}

.createTournamentContainer::-webkit-scrollbar {
  display: none;
}
