.preferences {
    margin: 10px 0;
    padding: 10px;
    border: 1px solid gold;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.05);
  }
  
  h3 {
    color: gold;
    margin-bottom: 8px;
    font-size: 18px;
    text-align: center;
  }
  
  .preferencesContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 300px;
    gap: 4px;
  }
  
  .formGroup {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .formGroup label {
    font-size: 14px;
    color: gold;
    margin-bottom: 2px;
  }
  
  select {
    width: 100%;
    padding: 4px 8px;
    background-color: #222;
    color: gold;
    border: 1px solid gold;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    padding: 0;
    margin: 0;
    width: auto;
  }
  
  select:focus {
    outline: none;
    box-shadow: 0 0 4px gold;
  }
  
  .error {
    color: red;
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
  }
  