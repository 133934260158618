:root {
  --background-dark: #1c1c29;
  --text-main: #f8f0c6;
  --text-gold: #ffd700;
  --border-color: #6d4e27;
  --card-bg: #2f2f3e;
  --highlight-text: #dcdcdc;
  --button-hover-bg: #8a6a45;
}

.marketContainer {
  color: var(--text-main);
  padding: 20px;
  margin: 0 auto;
  background-color: var(--background-dark);
  border-radius: 12px;
  border: 2px solid var(--border-color);
}

.marketTitle {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 20px;
  color: var(--text-gold);
}

/* Vendor List */
.vendorSelection {
  text-align: center;
  font-size: 1.1rem;
  color: var(--highlight-text);
}

.vendorList {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.vendorCard {
  background-color: var(--card-bg);
  border-radius: 8px;
  padding: 20px;
  width: 250px;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  text-align: center;
}

.vendorCard:hover {
  transform: scale(1.05);
}

.vendorName {
  margin: 0;
  color: var(--text-gold);
}

.vendorDescription {
  margin: 10px 0;
  color: white;
}

.backButton {
  background-color: var(--border-color);
  color: var(--text-main);
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  margin-bottom: 20px;
  transition: background-color 0.3s ease;
}

.backButton:hover {
  background-color: var(--button-hover-bg);
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tabButton {
  background-color: var(--card-bg);
  color: var(--text-gold);
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}

.tabButton:hover, .subCategoryButton:hover {
  background-color: var(--button-hover-bg);
}

.active {
  background-color: var(--border-color);
  color: var(--text-main);
}

.subCategoryButtons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.subCategoryButton {
  background-color: var(--card-bg);
  color: var(--text-gold);
  border: 1px solid var(--border-color);
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  margin: 5px;
  transition: background-color 0.3s ease;
}

.activeSubCategory {
  background-color: var(--border-color);
  color: var(--text-main);
}

.itemsTableContainer {
  max-width: 1000px;
  margin: 0 auto;
  overflow-x: auto;
}

.itemsTable {
  width: 100%;
  border-collapse: collapse;
  min-width: 600px;
}

.itemsTable th,
.itemsTable td {
  border: 1px solid #666;
  padding: 10px;
  text-align: center;
}

.itemsTable th {
  background-color: #292936;
}

.itemsTable td {
  background-color: var(--card-bg);
}

.buyButton {
  background-color: var(--border-color);
  color: var(--text-main);
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.buyButton:hover {
  background-color: var(--button-hover-bg);
}

@media (max-width: 768px) {
  .vendorList {
    flex-direction: column;
    align-items: center;
  }

  .itemsTable {
    font-size: 0.9rem;
  }

  .marketTitle {
    font-size: 2rem;
  }

  .tabButton {
    margin-bottom: 10px;
  }
}

@media (max-width: 480px) {
  .itemsTableContainer {
    overflow-x: auto;
  }

  .vendorCard {
    width: 100%;
  }
}
