.contractBattles {
    padding: 30px;
    background-color: #1c1c29;
    border: 2px solid #6d4e27;
    border-radius: 12px;
    color: #f8f0c6;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  }
  
  .title {
    text-align: center;
    font-size: 2rem;
    font-family: 'Cinzel', serif;
    color: #ffd700;
    margin-bottom: 20px;
  }
  
  .tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .tabs button {
    padding: 10px 20px;
    font-family: 'Cinzel', serif;
    background-color: #292936;
    color: #ffd700;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .tabs .activeTab {
    background-color: #6d4e27;
  }
  
  .sliderContainer {
    margin-bottom: 20px;
  }
  
  .rangeSlider {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .mercenaryList,
  .incomingSection ul {
    list-style: none;
    padding: 0;
  }
  
  .prepareButton {
    padding: 8px 15px;
    background-color: #6d4e27;
    color: #f8f0c6;
    border: none;
    border-radius: 5px;
    font-family: 'Cinzel', serif;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .prepareButton:hover {
    background-color: #8a6a45;
  }
  
  .challengeAllButton {
    margin-top: 20px;
  }
  
  .noBeastsMessage {
    text-align: center;
    color: #dcdcdc;
    font-size: 1.1rem;
  }
  
  /* Custom styles for rc-slider */
.rc-slider {
    margin: 0 auto;
    width: 90%;
    height: 10px;
  }
  
  .rc-slider-rail {
    background-color: #292936;
    height: 6px;
    border-radius: 5px;
  }
  
  .rc-slider-track {
    background-color: #ffd700;
    height: 6px;
    border-radius: 5px;
  }
  
  .rc-slider-handle {
    width: 16px;
    height: 16px;
    background-color: #6d4e27;
    border: 2px solid #ffd700;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  }
  
  .rc-slider-handle:active,
  .rc-slider-handle:focus {
    border-color: #8a6a45;
    box-shadow: 0 0 6px rgba(255, 215, 0, 0.75);
  }
  
  .rc-slider-handle:hover {
    border-color: #8a6a45;
  }
  
  .rc-slider-mark {
    color: #f8f0c6;
    font-family: 'Cinzel', serif;
    font-size: 12px;
  }
  
  .rc-slider-dot {
    background-color: #292936;
    border: 2px solid #6d4e27;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    position: absolute;
    bottom: -3px;
  }
  
  .rc-slider-dot-active {
    background-color: #ffd700;
    border-color: #ffd700;
  }
  
  .rc-slider-disabled {
    background-color: #444;
    cursor: not-allowed;
  }
  
  .filterSection {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    align-items: center;
  }
  .filterSection label {
    color: var(--text-light);
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .buttonGroup {
    display: flex;
    gap: 10px;
  }
  
  .acceptButton {
    padding: 8px 12px;
    background-color: #6d4e27;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .rejectButton {
    padding: 8px 12px;
    background-color: #8d0a06;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .acceptButton:hover {
    background-color: #8a6a45;
  }
  
  .rejectButton:hover {
    background-color: #b32222;
  }
  