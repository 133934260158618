.teamsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0;
  }
  
  .team {
    flex: 1;
    max-width: 48%;
    margin: 0 1%;
  }
  
  .team h3 {
    color: gold;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .participants {
    width: 100%;
  }
  
  .participants h3 {
    color: gold;
    text-align: center;
    margin-bottom: 10px;
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  li {
    margin: 5px 0;
    color: white;
  }
  
  button {
    margin-left: 10px;
    background-color: red;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
  }
  
  button:hover {
    background-color: darkred;
  }
  