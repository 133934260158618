.book {
    background: linear-gradient(135deg, #8b6b41, #d7b899);
    border: 2px solid #6d4e27;
    border-radius: 10px;
    box-shadow: 0 4px 15px #00000080;
    color: #3e2f1a;
    font-family: Cinzel, serif;
    height: 300px;
    overflow: hidden;
    padding: 10px;
    position: relative;
    width: 80%;
    min-height: 600px;
}

.page {
    width: 100%;
    height: calc(100% - 50px);
    padding: 20px;
    background-color: #f8f0c6;
    border-radius: 8px;
    position: relative;
    transform: perspective(1000px) rotateY(0deg);
    transition: transform 0.6s ease;
    box-sizing: border-box;
}

.controls {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 10px;
    width: calc(100% - 40px);
    padding: 0 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.pageButton {
    background-color: #6d4e27;
    color: #f8f0c6;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 5px;
}

.pageButton:hover {
    background-color: #8a6a45;
}

.pageButton:disabled {
    background-color: #b2a07a;
    cursor: not-allowed;
}
