/* AdventureList.css */

.adventure-list {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #1e1e1e;
    border: 1px solid #444;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
  }
  
  .adventure-list h2 {
    color: #d1a45f;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
  }
  
  .intro-text {
    color: #e1e1e1;
    font-style: italic;
    margin: 1rem 0;
    line-height: 1.6;
  }
  
  .start-adventure-button {
    background-color: #d1a45f;
    color: #1e1e1e;
    font-weight: bold;
    border: none;
    padding: 0.8rem 1.5rem;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
  }
  
  .start-adventure-button:hover {
    background-color: #e1e1e1;
    color: #d1a45f;
  }
  
  .adventure-list ul {
    list-style-type: none;
    padding: 0;
  }
  
  .adventure-list li {
    margin: 1rem 0;
  }
  
  .adventure-list li a {
    color: #d1a45f;
    font-weight: bold;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .adventure-list li a:hover {
    color: #e1e1e1;
  }
  
  .rarity {
    color: #c0c0c0;
    font-weight: normal;
    font-style: italic;
  }
  