@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;700&family=MedievalSharp&display=swap');

.tournamentListContainer {
  padding: 20px;
  background-color: #1c1c29;
  color: #f8f0c6;
  border-radius: 12px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  border: 2px solid #6d4e27;
  font-family: 'Cinzel', serif;
}

.tournamentHeader {
  font-family: 'MedievalSharp', serif;
  text-align: center;
  color: #ffd700;
  font-size: 2rem;
  margin-bottom: 20px;
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab {
  background-color: #2f2f3e;
  border: 1px solid #6d4e27;
  color: #ffd700;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: bold;
  font-family: 'Cinzel', serif;
  transition: background-color 0.3s, color 0.3s;
  margin-right: 10px;
  border-radius: 5px;
}

.tab.active {
  background-color: #6d4e27;
  color: #f8f0c6;
}

.tab:hover {
  background-color: #8a6a45;
}

.tournamentList {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.tournamentItem {
  background-color: #2c2c3a;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #ffd700;
  position: relative;
  transition: transform 0.2s, box-shadow 0.2s;
  font-family: 'Cinzel', serif;
}

.tournamentItem:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 15px #ffd700;
}

.tournamentTitle {
  color: #ffd700;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.tournamentDescription {
  color: #dcdcdc;
  margin-bottom: 10px;
}

.tournamentType,
.tournamentStatus,
.tournamentTeamSize,
.tournamentContestants,
.tournamentAvailableSpots,
.tournamentStartTime,
.tournamentLevels,
.tournamentRace {
  font-family: 'Cinzel', serif;
  margin-bottom: 8px;
}

.tournamentLink {
  color: #ffd700;
  text-decoration: none;
  font-weight: bold;
  font-family: 'Cinzel', serif;
  transition: color 0.3s, text-shadow 0.3s;
}

.tournamentLink:hover {
  color: #ffdd57;
  text-shadow: 0 0 10px #ffd700;
}

@media (max-width: 768px) {
  .tournamentListContainer {
    padding: 15px;
    max-width: 100%;
  }

  .tab {
    padding: 8px 15px;
    margin-right: 5px;
  }

  .tournamentItem {
    padding: 15px;
  }

  .tournamentTitle {
    font-size: 1.25rem;
  }

  .tournamentDescription,
  .tournamentType,
  .tournamentStatus,
  .tournamentTeamSize,
  .tournamentContestants,
  .tournamentAvailableSpots,
  .tournamentStartTime {
    font-size: 0.9rem;
  }

  .tournamentLink {
    font-size: 0.9rem;
  }
}
