.postPatchNote {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #1c1c29;
  color: #f8f0c6;
  border-radius: 8px;
  border: 2px solid #6d4e27;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.postPatchNote h2 {
  text-align: center;
  font-size: 24px;
  color: #ffd700;
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}

.input,
.textarea {
  width: 100%;
  max-width: 700px;
  padding: 10px;
  font-size: 16px;
  background-color: #292936;
  border: 1px solid #6d4e27;
  border-radius: 4px;
  color: #f8f0c6;
  outline: none;
}

.input::placeholder,
.textarea::placeholder {
  color: #aaa;
}

.textarea {
  min-height: 150px;
  resize: vertical;
}

.submitButton {
  margin-top: 15px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #f8f0c6;
  background-color: #6d4e27;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: center;
}

.submitButton:hover {
  background-color: #8a6a45;
}

.statusMessage {
  text-align: center;
  font-size: 14px;
  color: #77dd77;
}
