.auditLogContainer {
  padding: 20px;
  background-color: #1c1c29;
  border-radius: 12px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  color: #f8f0c6;
  border: 2px solid #6d4e27;
}

.auditLogContainer h1 {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #ffd700;
  text-align: center;
  font-family: 'Cinzel', serif;
}

.filterGroup {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filterGroup label {
  margin-right: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #ffd700;
  font-family: 'Cinzel', serif;
}

.filterSelect {
  padding: 8px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #6d4e27;
  background-color: #2f2f3e;
  color: #f8f0c6;
  outline: none;
  font-family: 'Cinzel', serif;
}

.auditLogTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.auditLogTable th,
.auditLogTable td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #666;
}

.auditLogTable th {
  background-color: #292936;
  color: #ffd700;
  font-weight: 600;
  font-family: 'Cinzel', serif;
}

.auditLogTable td {
  background-color: #2f2f3e;
  color: #f8f0c6;
  font-family: 'Cinzel', serif;
}

.auditLogTable tr:hover td {
  background-color: #393d42;
}

.pagination {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination button {
  padding: 10px 16px;
  margin: 0 5px;
  background-color: #6d4e27;
  color: #f8f0c6;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-family: 'Cinzel', serif;
  transition: background-color 0.3s ease;
}

.pagination button:hover:not(:disabled) {
  background-color: #8a6a45;
}

.pagination button:disabled {
  background-color: #4e5d6c;
  cursor: not-allowed;
}

.pagination span {
  font-size: 16px;
  color: #dcdcdc;
  margin: 0 10px;
  font-family: 'Cinzel', serif;
}
