.blueprintEditor {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    background-color: var(--background-dark);
    border-radius: 8px;
    border: 1px solid var(--border-color);
    color: var(--text-main);
  }
  
  h2 {
    text-align: center;
    font-size: 24px;
    color: var(--text-gold);
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    font-size: 14px;
    color: var(--highlight-text);
    margin-top: 10px;
    font-weight: bold;
  }
  
  input[type="text"],
  input[type="number"],
  textarea,
  select {
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background-color: var(--card-bg);
    color: var(--text-main);
  }
  
  textarea {
    resize: vertical;
    min-height: 80px;
  }
  
  .componentsSection {
    margin-top: 20px;
  }
  
  .searchResults {
    max-height: 150px;
    overflow-y: auto;
    background-color: var(--background-dark);
    border: 1px solid var(--border-color);
    border-radius: 4px;
    margin-top: 5px;
    padding: 10px;
  }
  
  .searchResults div {
    padding: 5px;
    color: var(--text-main);
    cursor: pointer;
  }
  
  .searchResults div:hover {
    background-color: var(--button-hover-bg);
  }
  
  ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  button {
    margin-top: 10px;
    background-color: var(--border-color);
    color: var(--text-main);
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: var(--button-hover-bg);
  }
  