.friendsContainer {
    padding: 20px;
    margin: auto;
    background-color: var(--background-dark);
    color: var(--highlight-text);
    border-radius: 15px;
    font-family: 'Cinzel', serif;
    border: 2px solid var(--border-color);
    max-width: 800px;
  }
  
  .title {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 1.5rem;
    color: var(--text-gold);
  }
  
  .friendsTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1.5rem;
  }
  
  .friendsTable th,
  .friendsTable td {
    padding: 10px;
    text-align: center;
    border: 1px solid var(--border-color);
    color: var(--text-light);
  }
  
  .friendsTable th {
    background-color: var(--card-bg);
    color: var(--highlight-text);
    font-weight: bold;
  }
  
  .friendsTable tbody tr:nth-child(even) {
    background-color: var(--card-bg);
  }
  
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  .profileLink {
    color: var(--text-gold);
    text-decoration: none;
    font-weight: bold;
  }
  
  .profileLink:hover {
    text-decoration: underline;
  }
  
  .mailIcon {
    margin-left: 8px;
    color: var(--highlight-text);
    cursor: pointer;
    transition: color 0.3s;
  }
  
  .mailIcon:hover {
    color: var(--text-gold);
  }
  
  .online {
    color: #4caf50;
    font-size: 0.8rem;
  }
  
  .offline {
    color: #d3d3d3;
    font-size: 0.8rem;
  }
  
  .removeButton {
    background-color: #440b0b;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: inherit;
    font-size: 0.9rem;
  }
  
  .removeButton:hover {
    background-color: #d9363e;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
  
  .pagination button {
    background-color: var(--card-bg);
    color: var(--highlight-text);
    padding: 5px 10px;
    margin: 0 5px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    cursor: pointer;
  }
  
  .pagination button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  button {
    margin-left: 4px;
  }