:root {
  --background-dark: #1c1c29;
  --text-gold: #ffd700;
  --text-light: #f8f0c6;
  --button-primary-bg: #6d4e27;
  --button-primary-hover-bg: #8a6a45;
  --border-color: #6d4e27;
  --card-bg: #2f2f3e;
  --card-hover-shadow: 0 0 15px #ffd700;
  --highlight-text: #ffdd57;
}

.teamBattlesContainer {
  padding: 20px;
  background-color: var(--background-dark);
  color: var(--text-gold);
  border-radius: 15px;
  border: 2px solid var(--border-color);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  font-family: 'Cinzel', serif;
}

.title {
  text-align: center;
  color: var(--text-gold);
  margin-bottom: 20px;
  font-size: 2rem;
}

.createLobbyButton {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  background-color: var(--button-primary-bg);
  color: var(--text-light);
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  font-family: 'Cinzel', serif;
  text-align: center;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.createLobbyButton:hover {
  background-color: var(--button-primary-hover-bg);
  box-shadow: var(--card-hover-shadow);
}

/* .filterOptions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  color: var(--text-gold);
  font-family: 'Cinzel', serif;
} */

.filterOptions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 20px;
}

.filterButton {
  padding: 10px 15px;
  background-color: #333;
  color: #ffd700;
  border: none;
  border-radius: 5px;
  font-family: 'Cinzel', serif;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.filterButton:hover {
  background-color: #63471f;
  transform: scale(1.05);
}

.activeFilter {
  background-color: #8a650a;
  color: white;
  font-weight: bold;
  transform: scale(1.05);
}

.filterOptions label {
  display: inline-block;
  background-color: #333;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.filterOptions label:hover {
  background-color: #63471f;
  transform: scale(1.05);
}

.filterOptions input {
  margin-right: 10px;
  display: none;
}

.filterOptions input:checked + label {
  background-color: #b8860b;
  color: #fff;
  transform: scale(1.05);
}

.lobbiesList {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.lobbyCard {
  background-color: var(--card-bg);
  padding: 20px;
  border-radius: 10px;
  position: relative;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
}

.lobbyCard:hover {
  transform: translateY(-5px);
  box-shadow: var(--card-hover-shadow);
}

.lobbyName {
  color: var(--text-gold);
  font-family: 'Cinzel', serif;
  font-size: 1.5rem;
  margin-bottom: 1px;
}

.lobbyLevel,
.lobbyParticipants,
.lobbyTeams {
  color: #dcdcdc;
  font-family: 'Cinzel', serif;
  margin: 0;
}

.lobbyTeams.random {
  color: #f5e353;
}

.noLobbies {
  text-align: center;
  font-size: 1.2rem;
  color: var(--highlight-text);
  font-family: 'Cinzel', serif;
}

@media (max-width: 768px) {
  .teamBattlesContainer {
    padding: 15px;
    max-width: 100%;
  }

  .filterOptions {
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }

  .lobbyCard {
    padding: 15px;
  }

  .lobbyName {
    font-size: 1.25rem;
  }

  .lobbyLevel,
  .lobbyParticipants,
  .lobbyTeams {
    font-size: 0.9rem;
  }

  .createLobbyButton {
    font-size: 1rem;
  }
}

.lobbyPve {
  color: #dcdcdc;
  font-family: 'Cinzel', serif;
  margin: 0;
}

.lobbyDeathmatch {
  color: #dcdcdc;
  font-family: 'Cinzel', serif;
  margin: 0;
}

.lobbyDeathmatch.deathmatch {
  color: #cb2323;
  font-weight: bold;
}

.lobbyCard {
  background-color: var(--card-bg);
  padding: 20px;
  border-radius: 10px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
}

.lobbyCard:hover {
  transform: translateY(-5px);
  box-shadow: var(--card-hover-shadow);
}

.lobbyCard.highlight {
  border: 2px solid var(--text-gold);
  background-color: var(--background-dark);
}