:root {
    --background-dark: #1c1c29;
    --text-main: #f8f0c6;
    --text-gold: #ffd700;
    --border-color: #6d4e27;
    --card-bg: #2f2f3e;
    --highlight-text: #dcdcdc;
    --button-hover-bg: #8a6a45;
  }
  
  .workDistrictContainer {
    color: var(--text-main);
    padding: 20px;
    background-color: var(--background-dark);
    border-radius: 12px;
    border: 2px solid var(--border-color);
  }
  
  .workTitle {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 20px;
    color: var(--text-gold);
  }
  
  .npcList {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    text-align: center;
  }
  
  .npcCard {
    background-color: var(--card-bg);
    border-radius: 8px;
    padding: 20px;
    width: calc(33.33% - 40px);
    max-width: 250px;
    min-width: 200px;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    text-align: center;
  }
  
  .npcCard:hover {
    transform: scale(1.05);
  }
  
  .npcName {
    margin: 0;
    color: var(--text-gold);
  }
  
  .npcDescription {
    margin: 10px 0;
    color: var(--text-main);
  }
  
  .backButton {
    background-color: var(--border-color);
    color: var(--text-main);
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    margin-bottom: 20px;
    transition: background-color 0.3s ease;
  }
  
  .backButton:hover {
    background-color: var(--button-hover-bg);
  }
  
  .roundOptions {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
  
  .optionButton {
    background-color: var(--card-bg);
    color: var(--text-gold);
    border: 1px solid var(--border-color);
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .optionButton:hover, .activeOption {
    background-color: var(--button-hover-bg);
    color: var(--text-main);
  }
  
  .workButton {
    background-color: var(--border-color);
    color: var(--text-main);
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .workButton:hover {
    background-color: var(--button-hover-bg);
  }
  
  @media (max-width: 1024px) {
    .npcCard {
      width: calc(50% - 40px);
    }
  }
  
  @media (max-width: 768px) {
    .npcCard {
      width: 100%;
    }
  
    .workTitle {
      font-size: 2rem;
    }
  }
  
  .selectContainer {
    margin-top: 20px;
    text-align: center;
  }
  
  .selectLabel {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .selectMenu {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    background-color: var(--card-bg);
    color: var(--text-main);
    border: 1px solid var(--border-color);
  }
  
  .redText {
    color: red;
  }
  
  .greenText {
    color: green;
  }

  .workDistrictDescription {
    font-size: 1.1rem;
    color: var(--text-main);
    margin-bottom: 20px;
    line-height: 1.6;
    text-align: justify;
  }

  .workDistrictDescription {
    font-size: 1.1rem;
    color: var(--text-main);
    margin-bottom: 20px;
    line-height: 1.6;
    text-align: justify;
    background-color: var(--card-bg);
    padding: 20px;
    border-radius: 8px;
    border: 1px solid var(--border-color);
  }
  
  .selectedNpc {
    background-color: var(--card-bg);
    padding: 20px;
    border-radius: 12px;
    border: 1px solid var(--border-color);
    margin-top: 20px;
  }
  
  .selectedNpc .npcDescription {
    font-size: 1.1rem;
    color: var(--text-main);
    line-height: 1.6;
    margin-top: 10px;
    padding: 15px;
    border-radius: 8px;
    text-align: center;
  }