/* CraftingProgress.module.css */

.craftingProgressContainer {
    padding: 20px;
    background-color: var(--background-dark);
    border-radius: 12px;
    border: 2px solid var(--border-color);
    color: var(--text-main);
    max-width: 800px;
    margin: 0 auto;
  }
  
  h2 {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 20px;
    color: var(--text-gold);
  }
  
  .projectTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .projectTable th,
  .projectTable td {
    padding: 10px;
    border: 1px solid var(--border-color);
    text-align: center;
  }
  
  .projectTable th {
    background-color: #292936;
  }
  
  .projectTable td {
    background-color: var(--card-bg);
  }
  
  .workButton {
    background-color: var(--border-color);
    color: var(--text-main);
    border: none;
    border-radius: 5px;
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: bold;
  }
  
  .workButton:hover {
    background-color: var(--button-hover-bg);
  }
  
  .noProjectsMessage {
    color: var(--highlight-text);
    text-align: center;
    font-size: 1.1rem;
    margin-top: 20px;
  }

  .itemIcon {
    width: 40px;
    height: 40px;
  }