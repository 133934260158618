.mercenaryProfileContainer {
  max-width: 900px;
  margin: 20px auto;
  padding: 25px;
  background-color: #1c1c29;
  border: 2px solid #6d4e27;
  border-radius: 12px;
  color: #f8f0c6;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.6);
}

.profileTitle {
  font-family: 'Cinzel', serif;
  text-align: center;
  font-size: 2.5rem;
  color: #ffd700;
  margin-bottom: 20px;
}

.profileSection {
  background-color: #2c2c3b;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.profileSection h2 {
  font-family: 'Cinzel', serif;
  font-size: 1.8rem;
  color: #ffd700;
  margin-bottom: 10px;
}

.profileSection p {
  font-family: Arial, sans-serif;
  font-size: 1rem;
  color: #e0e0e0;
  line-height: 1.5;
}

.equipmentSlot {
  margin-top: 15px;
}

.equipmentSlot h3 {
  font-family: 'Cinzel', serif;
  font-size: 1.4rem;
  color: #00bfff;
  margin-bottom: 5px;
}

.strong {
  font-weight: bold;
  color: #ffffff;
}

.errorMessage, .loadingMessage {
  text-align: center;
  font-size: 1.2rem;
  color: #ff6347;
  margin: 20px 0;
}
