:root {
  --background-dark: #1c1c29;
  --background-light-dark: #2f2f3e;
  --highlight-gold: #ffd700;
  --highlight-orange: #ffb84d;
  --main-text: #f8f0c6;
  --secondary-text: #dcdcdc;
  --border-color: #6d4e27;
  --button-bg: #6d4e27;
  --button-hover-bg: #8a6a45;
  --tab-bg: #3a3f51;
  --tab-active-bg: #a37904;
  --box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.tavernContainer {
  padding: 30px;
  background-color: var(--background-dark);
  color: var(--main-text);
  border-radius: 12px;
  box-shadow: var(--box-shadow);
  border: 2px solid var(--border-color);
}

/* Header styles */
.tavernHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.rpText h2 {
  font-size: 2.5rem;
  color: var(--highlight-gold);
}

.rpText p {
  font-size: 1.1rem;
  line-height: 1.5;
  color: var(--secondary-text);
}

.tavernImage img {
  max-width: 420px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Menu styles */
.menuTable {
  background-color: var(--background-light-dark);
  padding: 20px;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
}

.menuTitle {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  color: var(--highlight-orange);
  margin-bottom: 10px;
}

.menuTable table {
  width: 100%;
  border-collapse: collapse;
}

.menuTable th,
.menuTable td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #6b6045;
  color: var(--main-text);
}

.menuTable th {
  font-size: 1.1rem;
  color: var(--highlight-gold);
}

.menuTable td strong {
  display: block;
  font-size: 18px;
  color: var(--main-text);
  font-weight: bold;
}

.menuTable td em {
  display: block;
  font-size: 14px;
  color: var(--secondary-text);
}

/* Buy button */
.buyButton {
  padding: 8px 15px;
  font-size: 14px;
  font-weight: bold;
  color: var(--main-text);
  background-color: var(--button-bg);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.buyButton:hover {
  background-color: var(--button-hover-bg);
}

/* Tabs */
.tavernTabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  border-bottom: 2px solid #444;
}

.tavernTabs button {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  color: var(--main-text);
  background-color: var(--tab-bg);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.tavernTabs button.active {
  background-color: var(--tab-active-bg);
  font-weight: bold;
}

/* Content sections */
.tavernContent {
  padding: 20px;
  background-color: var(--background-light-dark);
  border-radius: 8px;
  color: var(--main-text);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.mysteriousContent {
  padding: 20px;
  background-color: var(--background-dark);
  border-radius: 8px;
  color: var(--main-text);
}

.mysteriousContent h3 {
  font-size: 24px;
  color: var(--highlight-orange);
}

.mysteriousContent p {
  font-size: 16px;
  line-height: 1.6;
  color: var(--secondary-text);
}

.teasingNote {
  font-style: italic;
  color: var(--highlight-orange);
  margin-top: 15px;
  text-align: center;
}

.chroniclesContent {
  padding: 20px;
  background-color: #2e2b26;
  border-radius: 8px;
  color: var(--main-text);
}

.chroniclesContent h3 {
  color: var(--highlight-orange);
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.patchNote {
  margin-bottom: 20px;
  padding: 15px;
  background-color: var(--background-light-dark);
  border-radius: 8px;
  box-shadow: var(--box-shadow);
}

.patchNote h4 {
  font-size: 24px;
  color: var(--highlight-orange);
}

.patchContent {
  font-size: 16px;
  line-height: 1.6;
  color: var(--main-text);
  text-align: left;
}

.patchVersion {
  font-size: 16px;
  color: #a8a8a8;
  margin-top: 10px;
  font-style: italic;
}

h1, h3, h4 {
  color: var(--highlight-gold);
  text-align: left;
}

.patchContent, h1 {
  text-align: left;
}

.patchHeader {
  text-align: left;
}