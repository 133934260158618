.toast {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 15px 25px;
    color: #f8f0c6;
    background-color: #1c1c29;
    border: 2px solid #6d4e27;
    border-radius: 12px;
    font-family: 'Cinzel', serif;
    font-size: 1.1rem;
    font-weight: bold;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    opacity: 0.9;
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.toast.success {
    background-color: #6d4e27;
    border-color: #8a6a45;
}

.toast.error {
    background-color: #8a2c2c;
    border-color: #b94545;
}

.toast.info {
    background-color: #2a3b5e;
    border-color: #3a506a;
}

.toast.fade-out {
    opacity: 0;
    transform: translateX(-50%) translateY(-20px);
}
