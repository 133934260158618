/* Adventure.css */

.adventure {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #1e1e1e;
    border: 1px solid #444;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
  }
  
  .adventure h2 {
    color: #d1a45f;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
  }
  
  .adventure p {
    font-style: italic;
    color: #ddd;
  }
  
  .adventure ul {
    list-style-type: none;
    padding: 0;
  }
  
  .adventure li {
    margin: 1rem 0;
  }
  
  .adventure button {
    background-color: #444;
    color: #d1a45f;
    border: 1px solid #d1a45f;
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-family: inherit;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .adventure button:hover {
    background-color: #d1a45f;
    color: #444;
  }
  