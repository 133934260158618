:root {
  --background-dark: #1c1c29;
  --background-light-dark: #2f2f3e;
  --highlight-gold: #ffd700;
  --main-text: #f8f0c6;
  --border-color: #6d4e27;
  --button-bg: #6d4e27;
  --button-hover-bg: #8a6a45;
}

.inventoryContainer {
  display: flex;
  gap: 20px;
  color: var(--main-text);
}

.equippedItemsSection {
  flex: 1;
  background-color: var(--background-dark);
  border: 1px solid #6d4e27;
  border-radius: 8px;
  padding: 20px;
}

.equippedGrid {
  display: grid;
  grid-template-columns: 100px 100px 100px;
  grid-template-rows: auto auto auto auto;
  grid-template-areas:
    ". head ."
    "mainHand torso shoulders"
    "offHand legs hands"
    ". feet .";
  grid-gap: 20px;
  justify-content: center;
}

.equippedSmallItemsGrid {
  display: grid;
  grid-template-columns: repeat(5, 60px);
  grid-gap: 20px;
  justify-content: center;
  margin-top: 20px;
}

.equippedSlot {
  background-color: var(--background-light-dark);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  width: 100px;
  height: 100px;
}

.equippedSlot h3 {
  color: var(--highlight-gold);
  margin: 8px;
  font-size: 14px;
}

.head { grid-area: head; }
.torso { grid-area: torso; }
.legs { grid-area: legs; }
.feet { grid-area: feet; }
.mainHand { grid-area: mainHand; }
.offHand { grid-area: offHand; }
.hands { grid-area: hands; }
.shoulders { grid-area: shoulders; }

.smallItemSlot {
  width: 60px;
  height: 60px;
}

.cloak, .neck, .amulet, .armring, .ring { grid-area: auto; }

.itemIcon {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.inventorySection {
  flex: 1;
  background-color: var(--background-dark);
  border: 1px solid #6d4e27;
  border-radius: 8px;
  padding: 20px;
}

.inventoryList {
  list-style-type: none;
  padding: 0;
}

.inventoryListItem {
  display: flex;
  align-items: center;
  background-color: var(--background-light-dark);
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.itemIcon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.inventoryListItem span {
  color: #fcfcfc;
  font-size: 14px;
}

.inventoryButton {
  background-color: var(--button-bg);
  color: var(--main-text);
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  margin-left: auto;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.inventoryButton:hover {
  background-color: var(--button-hover-bg);
}

.potionListItem {
  list-style: none;
  background-color: var(--background-light-dark);
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.activePotionsSection {
  width: 200px;
  background-color: var(--background-dark);
  border: 1px solid #6d4e27;
  border-radius: 8px;
  padding: 20px;
}

.potionList {
  padding: 0;
  margin: 0;
}

.potionListItem ul {
  padding-left: 20px;
}
