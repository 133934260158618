.register-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #3a3f51;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    color: #e0e0e0;
    font-family: Arial, sans-serif;
  }
  
  .register-container h2 {
    text-align: center;
    color: #ffd700;
    font-family: 'Cinzel', serif;
  }
  
  .register-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #444;
    background-color: #2b2b2b;
    color: #e0e0e0;
  }
  
  .form-group input:focus {
    border-color: #00aaff;
    outline: none;
  }
  
  .register-button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #444;
    color: #ffd700;
    font-family: 'Cinzel', serif;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .register-button:hover {
    background-color: #555;
  }
  
  .register-button:disabled {
    background-color: #666;
    cursor: not-allowed;
  }
  
  .error-message {
    color: #d9534f;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .success-message {
    color: #5cb85c;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
  }
  
  hr {
    margin: 20px 0;
    border: 0;
    border-top: 1px solid #444;
  }
  
  .login-link {
    color: #00aaff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .login-link:hover {
    text-decoration: underline;
  }
  