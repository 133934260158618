.active-orders {
    color: #f8f0c6;
    padding: 20px;
}

.orders-section {
    margin: 10px;
    height: 19em;
}

.orders-section h3 {
    font-family: 'Uncial Antiqua', serif;
    font-size: 20px;
    color: #ffd700;
    margin: 5px;
}

.orders-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 99%;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.orders-section ul::-webkit-scrollbar {
    display: none;
}

.orders-section li {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    font-family: 'Cinzel', serif;
    font-size: 14px;
    color: #dcdcdc;
    border-bottom: 1px solid rgba(109, 78, 39, 0.3);
}

.cancel-button {
    background-color: transparent;
    color: #ff4d4d;
    border: none;
    padding: 5px;
    cursor: pointer;
    font-weight: bold;
    border-radius: 5px;
    transition: background 0.3s;
    width: 24px;
}

.cancel-button:hover {
    background-color: transparent;
    border: 0.5px solid #ff4d4d;
    padding: 4px;
}

.orders-section li {
    border-bottom: 1px solid rgba(109, 78, 39, 0.3);
    color: #dcdcdc;
    display: flex;
    font-family: Cinzel, serif;
    font-size: 14px;
    justify-content: space-between;
    padding: 5px;
}

.orders-section li:hover {
    background-color: rgba(109, 78, 39, 0.2);
}

.order-item-name {
    flex-basis: 65%;
    text-align: left;
}

.order-item-qty {
    flex-basis: 10%;
    text-align: center;
}

.order-item-price {
    flex-basis: 10%;
    text-align: center;
}

.order-item-time {
    flex-basis: 15%;
    text-align: center;
}
