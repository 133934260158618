.auction-house {
    position: relative; /* Required for the ::after overlay */
    display: flex;
    flex-direction: column;
    background-image: url('https://njordheim.com/img/ah_texture.png');
    background-color: #1c1c29;
    color: #f8f0c6;
    margin: auto;
    border: 2px solid #6d4e27;
    padding: 10px;
    overflow: hidden;
}

.auction-house::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(28, 28, 41, 0.5);
    pointer-events: none;
    z-index: 0;
}

/* Position the actual content above the overlay */
.auction-house > * {
    position: relative;
    z-index: 1;
    font-family: 'Cinzel', serif;
}

/* Tabs Styling */
.tabs {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
}

.tab-button {
    background-color: rgba(28, 28, 41, 0.85);
    color: #f8f0c6;
    border: 2px solid rgba(109, 78, 39, 0.5);
    border-radius: 20px;
    padding: 10px 20px;
    margin: 3px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    font-family: 'Uncial Antiqua', serif;
    letter-spacing: .1em;
}

.tab-button.active {
    background-color: rgba(109, 78, 39, 0.9);
    color: #f8f0c6;
}

.tab-button:hover {
    background-color: rgba(109, 78, 39, 0.7);
}

/* Main Content Area */
.main-content {
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    flex-grow: 1;
}

/* Category and Subcategory Buttons */
.category-button, .subcategory-button {
    background-color: rgba(28, 28, 41, 0.85);
    color: #f8f0c6;
    border: none;
    padding: 8px 15px;
    margin: 5px 0;
    cursor: pointer;
    text-align: left;
    font-size: 14px;
    width: 100%;
    transition: background-color 0.3s;
    font-family: 'Uncial Antiqua', serif;
    letter-spacing: .1em;
}

.category-button.active {
    background-color: rgba(109, 78, 39, 0.9);
}

.category-button:hover {
    background-color: rgba(109, 78, 39, 0.7);
}

.subcategories {
    padding-left: 15px;
    margin-top: 5px;
}

.subcategory-button {
    font-size: 12px;
    padding: 5px 15px;
    margin: 3px 0;
}

.subcategory-button:hover {
    background-color: rgba(109, 78, 39, 0.7);
    color: #f8f0c6;
}

/* Content Area Styling */
.content {
    flex-grow: 1;
    padding: 15px;
    background-color: rgba(28, 28, 41, 0.85);
    border: 2px solid rgba(109, 78, 39, 0.5);
    border-radius: 5px;
}

/* Search Bar Styling */
.search-bar {
    background-color: rgba(28, 28, 41, 0.85);
    color: #f8f0c6;
    border: 2px solid rgba(109, 78, 39, 0.5);
    padding: 8px 12px;
    margin-bottom: 10px;
    width: calc(100% - 46px);
    border-radius: 3px;
    font-size: 14px;
    font-family: 'Cinzel', serif;
    transition: background-color 0.3s;
}

.search-bar::placeholder {
    color: #dcdcdc;
    font-family: 'Cinzel', serif;
}

.search-bar:focus {
    outline: none;
    background-color: rgba(109, 78, 39, 0.7);
}
