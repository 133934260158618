/* CategorySelection.css */

.category-selection {
    padding-right: 10px;
    width: 200px;
}

/* Category and Subcategory Buttons */
.category-button, .subcategory-button {
    background-color: #1c1c29;
    color: #f8f0c6;
    border: 2px solid #6d4e27;
    padding: 8px 15px;
    cursor: pointer;
    display: block;
    margin: 5px 0;
    text-align: left;
    font-size: 18px;
    transition: background-color 0.3s;
}

.category-button.active {
    background-color: #6d4e27;
}

.category-button:hover {
    background-color: #444556;
}

.subcategories {
    padding-left: 15px;
}

.subcategory-button {
    font-size: 14px;
    padding: 5px 15px;
    margin: 3px 0;
}

.subcategory-button:hover {
    background-color: #6d4e27;
    color: #ffd700;
}
