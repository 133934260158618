/* BeastEditor.module.css */
.beastEditor {
  padding: 20px;
  background-color: #1c1c29;
  color: #f8f0c6;
  max-width: 700px;
  margin: 40px auto;
  border-radius: 12px;
  border: 2px solid #6d4e27;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.dropdownSearch {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.dropdownSearch label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #ffd700;
}

.dropdownSearch select {
  padding: 8px;
  border-radius: 4px;
  background-color: #292936;
  color: #f8f0c6;
  border: 1px solid #6d4e27;
}

.tabButtons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.tabButtons button {
  padding: 10px 15px;
  border: none;
  background-color: #292936;
  color: #f8f0c6;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.tabButtons button.active {
  background-color: #6d4e27;
}

.tabContent {
  margin-top: 15px;
}

.formSection {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.formSection label {
  font-weight: bold;
  color: #ffd700;
}

.itemContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

.item {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 10px;
  flex: 1;
  min-width: 45%;
  max-width: 48%;
  color: #f8f0c6;
}

.addBtn,
.removeBtn,
.saveBtn {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  color: #f8f0c6;
  cursor: pointer;
  transition: background-color 0.2s;
}

.addBtn {
  background-color: #3b7e3b;
}

.removeBtn {
  background-color: #944e4e;
}

.saveBtn {
  background-color: #6d4e27;
  width: 100%;
  margin-top: 20px;
}

.saveBtn:hover {
  background-color: #8a6a45;
}

.lootList {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.lootItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border-radius: 4px;
  background-color: #292936;
  color: #f8f0c6;
}

.xBtn {
  padding: 2px 6px;
  border: none;
  background-color: #944e4e;
  color: #f8f0c6;
  cursor: pointer;
  border-radius: 50%;
  font-size: 0.9em;
  transition: background-color 0.2s;
}

.xBtn:hover {
  background-color: #bb6666;
}

.searchItem {
  padding: 5px;
  background-color: #292936;
  color: #f8f0c6;
  cursor: pointer;
  border-radius: 4px;
}

.searchItem:hover {
  background-color: #444;
}
