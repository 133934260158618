/* AdventureCompletion.css */

.adventure-completion {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #1e1e1e;
    border: 1px solid #444;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
  }
  
  .adventure-completion h2 {
    color: #d1a45f;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
  }
  
  .adventure-completion p {
    font-style: italic;
    color: #ddd;
  }
  
  .adventure-completion h3 {
    color: #d1a45f;
  }
  
  .adventure-completion ul {
    list-style-type: disc;
    margin-left: 1.5rem;
  }
  
  .adventure-completion li {
    font-size: 1.1rem;
    color: #d1a45f;
  }
  