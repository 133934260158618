/* Mail.module.css */
.mailContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: var(--background-dark);
    color: var(--text-light);
    border-radius: 15px;
    font-family: 'Cinzel', serif;
    border: 2px solid var(--border-color);
  }
  
  h1, h2, h3 {
    color: var(--text-gold);
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .inboxContainer {
    display: flex;
    width: 100%;
  }
  
  .inboxList {
    flex: 1;
    padding: 20px;
    border-right: 2px solid var(--border-color);
    background-color: var(--card-bg);
    border-radius: 15px 0 0 15px;
  }
  
  .inboxList ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .inboxList li {
    padding: 15px;
    margin-bottom: 10px;
    cursor: pointer;
    border-bottom: 1px solid var(--border-color);
    border-radius: 8px;
    transition: background-color 0.2s;
  }
  
  .inboxList li:hover {
    background-color: var(--background-dark);
  }
  
  .read {
    color: var(--text-light);
  }
  
  .unread {
    font-weight: bold;
    color: var(--highlight-gold);
  }
  
  .date {
    font-size: 0.8em;
    color: #bbb;
  }
  
  .mailViewer {
    flex: 2;
    padding: 20px;
    background-color: var(--card-bg);
    border-radius: 0 15px 15px 0;
  }
  
  .mailViewer h2 {
    margin-bottom: 0.5rem;
    color: var(--text-gold);
  }
  
  .replySection {
    margin-top: 20px;
  }
  
  .replySection textarea {
    width: 98%;
    min-height: 100px;
    padding: 10px;
    margin-bottom: 10px;
    background-color: var(--background-dark);
    border: 1px solid var(--border-color);
    color: var(--text-light);
    border-radius: 10px;
    font-family: inherit;
  }
  
  .replySection button {
    padding: 10px 15px;
    background-color: var(--border-color);
    color: var(--text-light);
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.2s;
  }
  
  .replySection button:hover {
    background-color: var(--button-hover-bg);
  }
  
  .replySection button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .closeButton {
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    color: var(--border-color);
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .closeButton:hover {
    color: var(--text-light);
    background: transparent;
  }

  .pagination {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
  }
  
  .sendMessageSection {
    padding: 20px;
  }
  
  .sendMessageSection h2 {
    margin-bottom: 10px;
  }
  
  .sendMessageSection input,
  .sendMessageSection textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
  }
  
  .sendMessageSection button {
    padding: 10px 20px;
    background-color: var(--border-color);
    color: var(--text-light);
    border: none;
    cursor: pointer;
    border-radius: 4px;
  }

  .sendMessageSection button:hover {
    background-color: var(--);
  }

  .mailContainer {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: var(--background-dark);
    color: var(--text-light);
    border-radius: 10px;
  }
  
  .inboxContainer {
    display: flex;
    gap: 20px;
  }
  
  .inboxList, .mailViewer {
    flex: 1;
    background-color: var(--card-bg);
    border-radius: 8px;
    padding: 15px;
    color: var(--highlight-text);
  }
  
  .messageContainer {
    background-color: var(--background-dark);
    border-radius: 8px;
    padding: 10px;
    margin: 10px 0;
  }
  
  .pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
  }
  
  .sendMessageSection {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .replySection {
    margin-top: 20px;
  }
  
  .searchInput, .replyTextarea {
    background-color: var(--background-dark);
    border: 1px solid var(--border-color);
    padding: 10px;
    border-radius: 5px;
    color: var(--text-light);
  }
  
  .searchInput::placeholder, .replyTextarea::placeholder {
    color: var(--text-gold);
  }
  
  .sendButton {
    background-color: var(--border-color);
    color: var(--text-light);
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
  }
  
  .sendButton:hover {
    background-color: var(--button-hover-bg);
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .overlayContent {
    background: #1c1c29;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-height: 80%;
    overflow-y: auto;
  }
  
  .closeOverlayButton {
    margin-top: 10px;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
  }

  .searchDropdown {
    background-color: #333;
    border: 1px solid #555;
    max-height: 150px;
    overflow-y: auto;
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 100;
  }
  
  .searchDropdown li {
    padding: 10px;
    cursor: pointer;
  }
  
  .searchDropdown li:hover {
    background-color: #444;
  }
  
  .messageHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  button {
    margin-top: 10px;
    background-color: var(--border-color);
    color: var(--text-main);
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: var(--button-hover-bg);
  }

  .recipientContainer {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .bookIcon {
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--text-main);
  }
  
  .searchInput {
    flex: 1;
    padding-left: 10px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
  }
  
  .pagination span {
    display: flex;
    align-items: center;
  }

  .searchResultItem {
    padding: 8px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .searchResultItem:hover {
    background-color: #f0f0f0;
  }

  .deleteButton {
    background: transparent;
    color: rgb(129, 2, 2);
    border: none;
    cursor: pointer;
    margin-left: 10px;
    padding: 0;
  }
  
  .deleteButton:hover {
    text-decoration: underline;
    background: transparent;
  }