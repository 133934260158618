:root {
  --background-dark: #1c1c29;
  --text-main: #f8f0c6;
  --text-gold: #ffd700;
  --link-color: #00bfff;
  --border-color: #6d4e27;
  --card-bg: #2f2f3e;
  --highlight-text: #dcdcdc;
  --progress-bar-bg: #555;
  --progress-bar-fill: #00bfff;
  --button-hover-bg: #8a6a45;
}

.adminContainer {
  display: flex;
  flex-direction: row;
  background-color: var(--background-dark);
  border: 2px solid var(--border-color);
  border-radius: 12px;
  padding: 20px;
  color: var(--text-main);
  min-height: 100vh;
  overflow-y: auto;
}

.adminSidebar {
  width: 250px;
  background-color: var(--card-bg);
  padding: 20px;
  color: var(--text-main);
  border-radius: 12px;
  flex-shrink: 0;
}

.adminSidebar h1 {
  color: var(--text-gold);
  font-family: 'Cinzel', serif;
  font-size: 1.3rem;
  margin-bottom: 20px;
}

.adminMenuList {
  list-style: none;
  padding: 0;
  font-size: 0.9rem;
}

.adminMenuList li {
  margin-bottom: 15px;
}

.adminMenuList li a {
  color: var(--link-color);
  text-decoration: none;
  display: flex;
  align-items: center;
}

.adminMenuList li a:hover {
  color: var(--text-gold);
}

.adminProgressBar {
  width: 100%;
  background-color: var(--progress-bar-bg);
  height: 8px;
  border-radius: 5px;
  margin-bottom: 15px;
}

.adminProgress {
  height: 100%;
  background-color: var(--progress-bar-fill);
  border-radius: 5px;
}

.adminMainContent {
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
  box-sizing: border-box;
}

.metricsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  width: 100%;
}

.metricCard {
  background-color: var(--card-bg);
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  color: var(--text-main);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.metricCard h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: var(--text-gold);
}

.metricCardContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.adminMdDropdownContainer {
  position: relative;
  margin-bottom: 20px;
}

.adminMdBtn {
  background-color: var(--border-color);
  color: var(--text-main);
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-family: 'Cinzel', serif;
  font-weight: bold;
}

.adminMdContent {
  display: none;
  position: absolute;
  background-color: var(--card-bg);
  min-width: 160px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 5px;
  right: 0;
}

.adminMdDropdownContainer:hover .adminMdContent {
  display: block;
}

.adminMdContent a {
  color: var(--text-main);
  padding: 10px;
  display: block;
  text-decoration: none;
}

.adminMdContent a:hover {
  background-color: var(--button-hover-bg);
}

@media (max-width: 768px) {
  .adminContainer {
    flex-direction: column;
  }

  .adminSidebar {
    width: 100%;
    margin-bottom: 20px;
  }
}
