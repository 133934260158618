.assignItemsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  background-color: #1c1c29; /* Background color */
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  color: #f8f0c6; /* Text color */
  border: 2px solid #6d4e27; /* Border color */
}

.assignItemsContainer h1 {
  color: #ffd700; /* Title color */
  font-family: 'Cinzel', serif;
  text-align: center;
  margin-bottom: 20px;
}

.assignItemsForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.formGroup {
  display: flex;
  flex-direction: column;
}

.formGroup label {
  color: #f8f0c6; /* Label text color */
  font-family: 'Cinzel', serif;
  margin-bottom: 5px;
}

.formGroup select,
.formGroup input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #6d4e27; /* Border color */
  font-family: 'Cinzel', serif;
  background-color: #2f2f3e; /* Input background */
  color: #ffd700; /* Text color */
}

.assignButton {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #6d4e27; /* Button background */
  color: #f8f0c6; /* Button text color */
  font-family: 'Cinzel', serif;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.assignButton:hover {
  background-color: #8a6a45; /* Hover effect */
}

.itemList {
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
}

.itemListEntry {
  cursor: pointer;
  color: #00bfff; /* Link color */
  margin: 5px 0;
}

.itemListEntry:hover {
  text-decoration: underline;
}

.itemPreview {
  margin-top: 20px;
  background-color: #2f2f3e; /* Preview background */
  padding: 15px;
  border-radius: 5px;
  width: 100%;
}

.itemPreview h3 {
  margin-top: 0;
  color: #ffd700; /* Title color */
}

.itemPreview img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-top: 10px;
}
