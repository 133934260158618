.chatBox {
    display: flex;
    flex-direction: column;
    background-color: #1e1e2f;
    border: 1px solid #3e3e5e;
    border-radius: 8px;
    padding: 10px;
    height: 400px;
    position: relative;
  }
  
  .chatHistory {
    flex: 1;
    overflow-y: scroll;
    padding: 10px;
    background-color: #232334;
    border-radius: 8px;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  
  .chatHistory::-webkit-scrollbar {
    display: none;
  }
  
  .timestamp {
    color: #909090;
    font-size: 12px;
    margin-right: 5px;
  }
  
  .chatMessage {
    margin-bottom: 5px;
    color: #ffffff;
  }
  
  .chatMessage .emoji {
    width: 36px;
    height: 36px;
    vertical-align: middle;
    display: inline-block;
  }
  
  .chatInputContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    position: relative;
    gap: 10px;
    margin: 0;
  }
  
  .chatInput {
    flex: 1;
    border: 1px solid #3e3e5e;
    border-radius: 5px;
    color: #ffffff;
    background-color: #2e2e48;
    height: 40px;
    margin: 0;
  }
  
  .emojiToggleButton,
  .sendButton {
    padding: 10px 15px;
    color: #ffffff;
    background-color: #8b4513;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
  
  .emojiToggleButton:hover,
  .sendButton:hover {
    background-color: #6b2e0b;
  }
  
  .emojiPickerOverlay {
    position: absolute;
    bottom: 70px;
    right: 80px;
    background-color: rgba(46, 46, 72, 0.95);
    border: 1px solid #3e3e5e;
    border-radius: 5px;
    padding: 10px;
    z-index: 100;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    max-width: 695px;
  }
  
  .userName {
    color: #f9c74f;
    font-weight: bold;
  }
  