.editItemContainer {
    padding: 20px;
    background-color: #1c1c29;
    color: #f8f0c6;
    border-radius: 10px;
    border: 2px solid #6d4e27;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  h1 {
    text-align: center;
    color: #ffd700;
  }
  
  .searchSection {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .searchSection input {
    flex-grow: 1;
    padding: 10px;
    margin-right: 10px;
    border-radius: 5px;
    border: 1px solid #6d4e27;
    background-color: #292936;
    color: #f8f0c6;
  }
  
  .searchSection button {
    padding: 10px;
    background-color: #6d4e27;
    color: #f8f0c6;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .searchSection button:hover {
    background-color: #8a6a45;
  }
  
  .searchResults {
    margin-bottom: 20px;
    max-height: 800px;
    overflow-y: auto;
  }
  
  .searchResult {
    padding: 10px;
    background-color: #292936;
    border-bottom: 1px solid #6d4e27;
    cursor: pointer;
    color: #ffd700;
  }
  
  .searchResult:hover {
    background-color: #3a3a47;
  }
  
  .formSection {
    background-color: #292936;
    padding: 20px;
    border-radius: 10px;
    border: 2px solid #6d4e27;
  }
  
  .formGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  
  .formGroup label {
    margin-bottom: 5px;
    color: #ffd700;
  }
  
  .formGroup input,
  .formGroup select,
  .formGroup textarea {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #6d4e27;
    background-color: #292936;
    color: #f8f0c6;
  }
  
  .formGroup input:focus,
  .formGroup select:focus,
  .formGroup textarea:focus {
    border-color: #ffd700;
    outline: none;
  }
  
  textarea {
    height: 80px;
    resize: vertical;
  }
  
  .saveButton {
    background-color: #6d4e27;
    color: #f8f0c6;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
    font-size: 16px;
  }
  
  .saveButton:hover {
    background-color: #8a6a45;
  }
  
  .checkboxGroup {
    display: flex;
    align-items: center;
  }
  
  .checkboxGroup label {
    margin-right: 10px;
    color: #ffd700;
  }
  
  .message {
    margin-top: 10px;
    color: #ffd700;
    text-align: center;
  }
  
  .checkboxGroup select {
    padding: 5px;
    width: 5%;
  }