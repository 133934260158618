.history {
    display: flex;
    flex-direction: column;
    color: #f8f0c6;
    padding: 20px;
    font-family: 'Cinzel', serif;
}

.history h2 {
    margin: 3px;
    color: #ffd700;
}

.history-list-container {
    flex-grow: 1;
    overflow-y: auto;
}

.history-list {
    display: flex;
    flex-direction: column;
    gap: 3px;
    padding-right: 10px;
}

.history-record {
    display: flex;
    justify-content: space-between;
    padding: 3px;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid #6d4e27;
    color: #f8f0c6;
}

.history-record.expired-tint {
    background-color: rgba(157, 0, 0, 0.15);
}

.history-record.canceled-tint {
    background-color: rgba(145, 114, 0, 0.15);
}

.history-record.fulfilled-tint {
    background-color: rgba(34, 139, 34, 0.15);
}

.item-name {
    width: 55%;
}

.quantity {
    width: 10%;
}

.price {
    width: 10%;
}

.status {
    width: 15%;
}

.date {
    width: 10%;
}

.pagination {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: auto;
    padding-top: 30px;
    border-top: 1px solid #6d4e27;
}

.pagination button {
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
    background-color: #6d4e27;
    border: none;
    color: #f8f0c6;
    border-radius: 5px;
    width: 120px;
}

.pagination button:disabled {
    background-color: #444556;
    cursor: not-allowed;
}
