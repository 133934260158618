.createItemContainer {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #1c1c29;
  color: #f8f0c6;
  border-radius: 10px;
  border: 2px solid #6d4e27;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

h1 {
  text-align: center;
  color: #ffd700;
}

.formGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.formGroup label {
  margin-bottom: 5px;
  color: #ffd700;
}

.formGroup input,
.formGroup select,
.formGroup textarea {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #6d4e27;
  background-color: #292936;
  color: #f8f0c6;
}

.formGroup input:focus,
.formGroup select:focus,
.formGroup textarea:focus {
  border-color: #ffd700;
  outline: none;
}

textarea {
  height: 80px;
  resize: vertical;
}

.submitButton {
  background-color: #6d4e27;
  color: #f8f0c6;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  font-size: 16px;
}

.submitButton:hover {
  background-color: #8a6a45;
}
