.broadcastContainer {
    background-color: var(--background-dark);
    color: var(--text-light);
    padding: 20px;
    border-radius: 10px;
    max-width: 600px;
    margin: auto;
    text-align: center;
  }
  
  .formGroup {
    margin-bottom: 15px;
  }
  
  .input, .textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    background-color: var(--background-light);
    color: var(--text-light);
  }
  
  .textarea {
    min-height: 100px;
  }
  
  .sendButton {
    padding: 10px 20px;
    background-color: var(--highlight-text);
    color: var(--text-dark);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .statusMessage {
    margin-top: 15px;
    color: var(--text-gold);
  }
  