/* Page Layout */
.landingPage {
  display: flex;
  flex-direction: column;
  background-color: #1c1c299a;
  color: #f8f0c6;
  font-family: 'Georgia', serif;
  height: 100vh;
}

.landingHeader {
  background-color: #1a1a1a;
  padding: 20px;
  text-align: center;
  color: #ffd700;
  font-size: 2.5rem;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Content Area */
.landingContent {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  gap: 40px;
  padding: 0 20px;
}

/* Information Section */
.infoSection {
  max-width: 500px;
  text-align: left;
}

.infoSection h2 {
  font-size: 2rem;
  color: #ffd700;
  margin-bottom: 15px;
  text-shadow: 0 0 10px rgba(255, 215, 0, 0.6);
}

.infoSection p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #cccccc;
  margin-bottom: 15px;
}

.discordLink {
  color: #ffd700;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.3s ease;
}

.discordLink:hover {
  color: #e1c068;
  text-decoration: underline;
}

/* Authentication Section */
.authSection {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 400px;
}

.authForm {
  background-color: #2f2f3e;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
  width: 100%;
  transition: all 0.3s ease;
}

/* Primary Button Styling */
.authForm button,
.toggleFormButton {
  width: 100%;
  background-color: #6d4e27;
  color: #f8f0c6;
  border: none;
  padding: 12px 0;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-top: 15px;
}

.authForm button:hover,
.toggleFormButton:hover {
  background-color: #8a6a45;
  transform: scale(1.03);
}

.authForm button:focus,
.toggleFormButton:focus {
  outline: none;
  box-shadow: 0 0 6px rgba(255, 215, 0, 0.5);
}

/* Login and Register Form Buttons */
.authForm .loginButton {
  background-color: #ffd700;
}

.authForm .loginButton:hover {
  background-color: #e1c068;
}

.authForm .discordButton {
  background-color: #7289da;
}

.authForm .discordButton:hover {
  background-color: #5b6ebf;
}

/* Toggle Button */
.toggleFormButton {
  background-color: #ffd700;
  color: #121212;
  border: none;
  padding: 12px 0;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.toggleFormButton:hover {
  background-color: #e1c068;
  transform: scale(1.03);
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .landingContent {
    flex-direction: column;
    gap: 20px;
  }

  .infoSection, .authSection {
    max-width: 100%;
    text-align: center;
  }

  .infoSection h2 {
    font-size: 1.8rem;
  }

  .infoSection p {
    font-size: 1rem;
  }
}
