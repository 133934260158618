:root {
    --background-dark: #1c1c29;
    --header-background: #2a2a3d;
    --summary-background: #333333;
    --round-background: #444444;
    --text-gold: #ffd700;
    --text-light: #f8f0c6;
    --text-medium: #e0e0e0;
    --text-red: #da2525;
    --text-green: #66b965;
    --text-blue: #3399ff;
    --border-color: #6d4e27;
  }
  
  .battleContainer {
    padding: 20px;
    background-color: var(--background-dark);
    color: var(--text-light);
    font-family: 'Cinzel', serif;
    border-radius: 12px;
    border: 2px solid var(--border-color);
  }
  
  .infoPanel {
    margin-bottom: 20px;
    padding: 15px;
    background-color: var(--header-background);
    border: 2px solid var(--border-color);
    border-radius: 8px;
  }
  
  .infoPanel h2 {
    color: var(--text-gold);
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
  
  .infoPanel p {
    margin: 5px 0;
    font-size: 1.2rem;
    text-align: center;
  }
  
  .combatLog {
    font-size: 1.1rem;
    line-height: 1.6;
    background-color: var(--background-dark);
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
    border: 1px solid var(--border-color);
  }
  
  .roundHeader {
    font-size: 1.3rem;
    text-align: center;
    color: var(--text-gold);
    background-color: var(--round-background);
    padding: 5px;
    margin: 15px 0;
    border-radius: 5px;
    border: 1px solid var(--border-color);
  }

  .teamPreviews {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .teamPreview {
    background-color: var(--header-background);
    padding: 10px;
    border: 2px solid var(--border-color);
    border-radius: 8px;
    width: 48%;
  }
  
  .teamPreview h4 {
    color: var(--text-gold);
    font-size: 1.2rem;
    margin: 0;
  }
  
  .teamPreview ul {
    list-style-type: none;
    padding: 0;
  }
  
  .teamPreview li {
    font-size: 1rem;
    margin: 5px 0;
  }
    
  .participant {
    font-size: 1rem;
  }
  
  .participant.green {
    color: var(--text-green);
  }
  
  .participant.blue {
    color: var(--text-blue);
  }
  
  .participant.red {
    color: var(--text-red);
  }
  
  .summaryHeading {
    color: var(--text-gold);
    font-size: 1.5rem;
    text-align: center;
    margin: 20px 0;
  }
  
  .summaryTables {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .summaryTable {
    width: 76vh;
    border-collapse: collapse;
    background-color: var(--background-dark);
    border: 2px solid var(--border-color);
    border-radius: 8px;
  }
  
  @media (max-width: 768px) {
    .summaryTable {
      width: 100%;
    }
  }
  
  .summaryTable th {
    background-color: var(--header-background);
    color: var(--text-gold);
    padding: 10px;
    text-align: left;
    font-size: 1rem;
    border: 1px solid var(--border-color);
  }
  
  .summaryTable td {
    padding: 10px;
    color: var(--text-light);
    text-align: left;
    font-size: 0.95rem;
    border: 1px solid var(--border-color);
  }
  
  .summaryTable tfoot td {
    font-weight: bold;
    background-color: var(--header-background);
    color: var(--text-gold);
  }
  
  .rewardsSection {
    margin-top: 20px;
    padding: 15px;
    background-color: #2a2a3d;
    border: 2px solid var(--border-color);
    border-radius: 8px;
    font-size: 0.9rem;
  }
  
  .rewardsSection h4 {
    color: var(--text-gold);
    margin-bottom: 10px;
    font-size: 1.2rem;
    margin: 0;
  }
  
  .rewardsSection p {
    margin: 5px 0;
    color: var(--text-light);
  }