.createMercenaryContainer {
  padding: 20px;
  background-color: rgba(60, 60, 60, 0.9);
  border: 2px solid #888;
  border-radius: 10px;
  max-width: 600px;
  margin: 0 auto;
}

.createMercenaryRow {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 15px;
}

.createMercenaryRow > div {
  flex: 1;
}

label {
  font-family: 'Cinzel', serif;
  color: #f0f0f0;
  margin-bottom: 5px;
}

input, select {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #888;
  font-family: 'Cinzel', serif;
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
}

.avatarSection {
  text-align: center;
  margin-bottom: 20px;
}

.avatarGrid {
  display: grid;
  /* grid-template-columns: repeat(3, 1fr); */
  grid-template-columns: repeat(3, 160px);
  justify-content: center;
  gap: 8px;
  margin-bottom: 10px;
}

.avatarItem {
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 5px;
  width: 160px;
  height: 160px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatarItem img {
  width: 100%;
  height: 100%;
  justify-content: center;
  object-fit: cover;
  border-radius: 5px;
}

.avatarItem.selected {
  border-color: gold;
  box-shadow: 0 0 0 2px rgba(255, 215, 0, 0.5);
}

.primarySkillSection {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.attributesSection,
.weaponSkillsSection {
  flex: 1;
  margin-right: 10px;
}

.weaponSkillsSection {
  margin-right: 0;
}

h3 {
  font-family: 'Cinzel', serif;
  color: #ffd700;
  margin-bottom: 10px;
}

.createSkillInput {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 95%;
  border: 1px solid #888;
  border-radius: 5px;
  padding: 5px;
}

.createSkillInput label {
  flex: 2;
  text-align: left;
}

.createSkillInput input {
  flex: 1;
  max-width: 60px;
  text-align: center;
}

.createSkillInput .modifier {
  flex: 1;
  text-align: right;
  color: #ffd700;
}

.secondarySkillSection {
  margin-top: 20px;
}

.submitButton {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #444;
  color: #ffd700;
  font-family: 'Cinzel', serif;
  cursor: pointer;
}

.submitButton:hover {
  background-color: #555;
}

.errorText {
  color: red;
  font-family: 'Cinzel', serif;
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
}
