.sell-order-form {
    color: #f8f0c6;
    padding: 20px;
    border-radius: 10px;
    border: 2px solid #6d4e27;
    max-width: 400px;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.sell-order-form h2 {
    font-family: 'Uncial Antiqua', serif;
    font-size: 24px;
    color: #ffd700;
    text-align: center;
    margin-bottom: 15px;
}

.sell-order-form .form-group {
    margin-bottom: 15px;
    font-family: 'Cinzel', serif;
}

.sell-order-form label {
    display: block;
    margin-top: 8px;
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 14px;
    color: #d4c4a8;
}

.sell-order-form select {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    font-family: 'Cinzel', serif;
    color: #f8f0c6;
    background-color: #1c1c29;
    border: 2px solid #6d4e27;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.sell-order-form input[type="number"] {
    width: 94%;
    padding: 10px;
    font-size: 14px;
    font-family: 'Cinzel', serif;
    color: #f8f0c6;
    background-color: #1c1c29;
    border: 2px solid #6d4e27;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.sell-order-form input[type="number"]:focus,
.sell-order-form select:focus {
    outline: none;
    background-color: #2f2f3e;
}

.sell-order-form button[type="submit"] {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-family: 'Cinzel', serif;
    color: #f8f0c6;
    background-color: #6d4e27;
    border: 2px solid #6d4e27;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.sell-order-form button[type="submit"]:hover {
    background-color: #8a6a45;
}

.sell-order-form p {
    font-size: 14px;
    text-align: center;
    color: #ffd700;
    margin-top: 10px;
}

.duration-buttons {
    display: flex;
    gap: 10px;
    margin-top: 8px;
}

.duration-button {
    flex: 1;
    padding: 8px 10px;
    font-size: 14px;
    font-family: 'Cinzel', serif;
    color: #f8f0c6;
    background-color: #1c1c29;
    border: 2px solid #6d4e27;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.duration-button.active {
    background-color: #2f2f3e;
}

.duration-button:hover {
    background-color: #2f2f3e;
}

.duration-time {
    display: block;
    font-weight: 700;
}

.sell-order-form-container {
    display: flex;
    gap: 20px;
    color: #f8f0c6;
    padding: 20px;
    height: 88%;
}

.sell-order-form {
    width: 50%;
    padding: 20px;
    border-radius: 10px;
    border: 2px solid #6d4e27;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.additional-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.current-listings, .sales-history {
    background-color: #1c1c29;
    border-radius: 8px;
    border: 1px solid #6d4e27;
    color: #f8f0c6;
    font-family: 'Cinzel', serif;
    overflow: hidden;
    width: 100%;
    height: 322px;
    overflow-y: auto;
    border: 2px solid #6d4e27;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.current-listings h3, .sales-history h3 {
    font-family: 'Uncial Antiqua', serif;
    font-size: 20px;
    color: #ffd700;
    margin-bottom: 10px;
}

.current-listings ul {
    list-style: none;
    padding: 0;
    margin: 0;
    overflow-y: auto;
    scrollbar-width: none;
}

.current-listings ul::-webkit-scrollbar {
    display: none;
}

.current-listings li {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    font-family: 'Cinzel', serif;
    font-size: 14px;
    border-bottom: 1px solid #6d4e27;
    color: #d4c4a8;
}

.sales-history canvas {
    max-height: 200px;
}

.current-listings h3, .sales-history h3 {
    font-family: 'Uncial Antiqua', serif;
    font-size: 18px;
    color: #ffd700;
    text-align: left;
    margin-bottom: 10px;
}

.current-listings p, .sales-history p {
    color: #d4c4a8;
    font-size: 14px;
    text-align: center;
}

.current-listings ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.current-listings li {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    border-bottom: 1px solid #6d4e27;
}

.current-listings li:last-child {
    border-bottom: none;
}

.current-listings li span {
    font-size: 14px;
    color: #f8f0c6;
}

.current-listings li:nth-child(odd) {
    background-color: rgba(255, 255, 255, 0.05);
}

.current-listings li:nth-child(even) {
    background-color: rgba(255, 255, 255, 0.08);
}

.sales-history .chart-container {
    height: 220px;
    width: 100%;
    max-width: 1000px;
}

.sell-order-container {
    display: flex;
    gap: 20px;
    padding: 20px;
    color: #f8f0c6;
}

.sell-order-form {
    flex: 1;
    padding: 20px;
    border-radius: 10px;
    border: 2px solid #6d4e27;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.additional-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.current-listings h3, .sales-history h3 {
    font-family: 'Uncial Antiqua', serif;
    font-size: 20px;
    color: #ffd700;
    margin: 8px;
}

.current-listings ul {
    list-style: none;
    padding: 2px;
    margin: 2px;
}

.current-listings li {
    display: flex;
    justify-content: space-between;
    padding: 4px;
    font-family: 'Cinzel', serif;
    font-size: 14px;
    border-bottom: 1px solid #6d4e27;
}

.current-listings li:hover {
    background-color: rgba(109, 78, 39, 0.2);
}

.current-listings .item-name {
    flex-basis: 75%;
    text-align: left;
}

.current-listings .item-qty {
    flex-basis: 10%;
    text-align: right;
    padding-right: 10px;
}

.current-listings .item-price {
    flex-basis: 15%;
    text-align: right;
}

.chart-container {
    width: 100%;
    height: 260px;
    max-width: 100%;
    position: relative;
}
