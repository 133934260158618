.deathscreen-container {
    padding: 20px;
    background-color: #1e1e1e;
    color: #f0f0f0;
    border-radius: 10px;
    margin: 0 auto;
  }
  
  .create-new-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #ff4b4b;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    width: 240px;
  }
  
  .create-new-button:hover {
    background-color: #e04343;
  }
  