:root {
  --background-dark: #1c1c29;
  --background-light-dark: #2f2f3e;
  --highlight-gold: #ffd700;
  --main-text: #f8f0c6;
  --secondary-text: #d3d3d3;
  --border-color: #6d4e27;
  --button-bg: #6d4e27;
  --button-hover-bg: #8a6a45;
  --box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.diceGame {
  padding: 20px;
  background-color: var(--background-light-dark);
  border-radius: 8px;
  color: var(--main-text);
  text-align: center;
  box-shadow: var(--box-shadow);
}

.betControls {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
}

.plainInput {
  width: 100px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  text-align: center;
  appearance: none;
  -moz-appearance: textfield;
}

.plainInput::-webkit-outer-spin-button,
.plainInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.rollButton {
  padding: 6px 12px;
  font-weight: bold;
  color: var(--main-text);
  background-color: var(--button-bg);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.rollButton:hover {
  background-color: var(--button-hover-bg);
}

.result {
  font-size: 18px;
  margin-top: 15px;
  color: var(--highlight-gold);
}
