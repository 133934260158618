.beastsContainer {
  padding: 30px;
  background-color: #1c1c29;
  border: 2px solid #6d4e27;
  border-radius: 12px;
  color: #f8f0c6;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.title {
  text-align: center;
  font-size: 2rem;
  font-family: 'Cinzel', serif;
  color: #ffd700;
  margin-bottom: 20px;
}

.description {
  text-align: center;
  font-size: 1.1rem;
  color: #dcdcdc;
  margin-bottom: 15px;
}

.filterContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.filterLabel {
  font-size: 1rem;
  color: #ffd700;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.filterLabel input{
  display: none;
}

.beastsTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-size: 1rem;
}

.beastsTable th,
.beastsTable td {
  border: 1px solid #666;
  padding: 10px;
  text-align: center;
  font-family: 'Cinzel', serif;
}

.beastsTable th {
  background-color: #292936;
  color: #ffd700;
  font-size: 1.1rem;
}

.beastsTable td {
  background-color: #2f2f3e;
}

.prepareButton {
  padding: 8px 15px;
  background-color: #6d4e27;
  color: #f8f0c6;
  border: none;
  border-radius: 5px;
  font-family: 'Cinzel', serif;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.prepareButton:hover {
  background-color: #8a6a45;
}

.noBeastsMessage {
  text-align: center;
  color: #dcdcdc;
  font-size: 1.1rem;
  margin-top: 20px;
}
