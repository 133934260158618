:root {
  --background-dark: #1c1c29;
  --text-gold: #ffd700;
  --text-light: #f8f0c6;
  --text-medium: #e0e0e0;
  --button-bg: #6d4e27;
  --button-hover-bg: #8a6a45;
  --border-color: #6d4e27;
  --input-bg: #333333;
}

.duelContainer {
  padding: 20px;
  background-color: var(--background-dark);
  color: var(--text-light);
  border-radius: 12px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  border: 2px solid var(--border-color);
  font-family: 'Cinzel', serif;
}

h1 {
  text-align: center;
  color: var(--text-medium);
  font-size: 2rem;
}

.introText, .moreText {
  margin: 15px 0;
  font-size: 1rem;
  color: var(--text-light);
}

.toggleTextButton {
  display: block;
  margin: 0 auto;
  background-color: var(--input-bg);
  color: var(--text-gold);
  padding: 8px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggleTextButton:hover {
  background-color: #444;
}

.duelForm, .queueInfo {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.formGroup {
  display: flex;
  flex-direction: column;
}

.formControl {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid var(--border-color);
  background-color: var(--input-bg);
  color: var(--text-gold);
}

.submitButton, .leaveButton {
  background-color: var(--button-bg);
  color: var(--text-light);
  border: none;
  padding: 12px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.submitButton:hover, .leaveButton:hover {
  background-color: var(--button-hover-bg);
}

.resultMessage, .healMessage {
  text-align: center;
  font-size: 1.1rem;
  color: var(--text-medium);
  margin-top: 10px;
}

.errorMessage {
  color: #ff6347;
  text-align: center;
}
