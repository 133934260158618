:root {
  --background-dark: #1c1c29;
  --text-gold: #ffd700;
  --text-light: #f8f0c6;
  --text-medium: #e0e0e0;
  --button-bg: #6d4e27; /* Updated to match the previous button color */
  --button-hover-bg: #8a6a45; /* Updated hover color */
  --button-disabled-bg: #7d6a52;
  --input-bg: #333333;
  --border-color: #6d4e27; /* Updated border color to match the previous border */
}

.beastBattleContainer {
  padding: 25px;
  background-color: var(--background-dark);
  color: var(--text-gold);
  border-radius: 12px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  border: 2px solid var(--border-color); /* Added border */
  font-family: 'Cinzel', serif;
}

.beastBattleContainer h2 {
  text-align: center;
  color: var(--text-gold);
  font-size: 2rem;
  margin-bottom: 15px;
}

.beastDetails {
  margin-bottom: 20px;
  color: var(--text-light);
}

.beastDetails h3 {
  color: var(--text-gold);
  font-size: 1.6rem;
  margin-bottom: 10px;
}

.beastDetails p {
  margin: 5px 0;
  font-size: 1.1rem;
}

.battlePreparationForm {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.formGroup {
  display: flex;
  flex-direction: column;
}

.formGroup label {
  color: var(--text-medium);
  font-size: 1rem;
  margin-bottom: 5px;
}

.formGroup select {
  padding: 10px;
  border-radius: 6px;
  border: 1px solid var(--border-color);
  font-family: 'Cinzel', serif;
  background-color: var(--input-bg);
  color: var(--text-gold);
}

.battleButton {
  padding: 12px 20px;
  background-color: var(--button-bg);
  color: var(--text-light);
  border: none;
  border-radius: 6px;
  font-family: 'Cinzel', serif;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.battleButton:disabled {
  background-color: var(--button-disabled-bg);
  cursor: not-allowed;
}

.battleButton:hover:not(:disabled) {
  background-color: var(--button-hover-bg);
}

.errorMessage {
  color: #ff4c4c;
  text-align: center;
  font-size: 1rem;
  margin-top: -5px;
}
