/* LeftNavBar.module.css */

:root {
  --background-dark: #1c1c29;
  --background-medium: #33394d;
  --text-light: #f8f0c6;
  --highlight: #ffd700;
  --border-color: #6d4e27;
  --link-color: #ffd700;
  --link-hover-color: #f8f0c6;
  --button-primary-bg: #6d4e27;
  --button-primary-hover-bg: #5a3e22;
  --button-danger-bg: #ff4c4c;
  --button-danger-hover-bg: #ff1c1c;
}

.leftMenu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 18vh;
  background-color: var(--background-dark);
  color: var(--text-light);
  border-right: 1px solid var(--border-color);
  overflow-y: auto;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.5);
  position: sticky;
  top: 0;
  padding: 20px 10px;
}

.navLinks {
  display: flex;
  flex-direction: column;
}

.navLink {
  color: var(--text-light);
  text-decoration: none;
  padding: 12px 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease, padding-left 0.3s ease;
}

.icon {
  margin-right: 10px;
}

.navLink:hover {
  background-color: var(--border-color);
  padding-left: 20px;
}

.navLink:active {
  background-color: #57606f;
}

.categoryTitle {
  color: var(--highlight);
  font-size: 1.1em;
  margin: 15px 0 5px;
}

.logoutContainer {
  margin-top: auto;
}

.logoutButton {
  width: 100%;
  padding: 10px;
  background-color: var(--button-danger-bg);
  background-color: #440b0b;
  border: 1px solid #454545;
  color: var(--text-light);
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin: auto;
}

.logoutButton:hover {
  background-color: var(--button-danger-hover-bg);
}

.icon, .svgIcon {
  width: 24px;
  height: 24px;
  fill: var(--text-light);
  color: var(--text-light);
}

.svgIcon {
  width: 24px;
  height: 24px;
  fill: var(--text-light);
}