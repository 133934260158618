/* Layout */
.buy-order-form-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 20px;
}

/* Create Buy Order Form */
.create-buy-order-form {
    grid-column: 1 / 2;
    padding: 10px;
    background-color: #1c1c29;
    border-radius: 8px;
    border: 2px solid #6d4e27;
    height: 42em;
    margin: 6px;
}

.create-buy-order-form h2 {
    font-family: 'Uncial Antiqua', serif;
    font-size: 24px;
    color: #ffd700;
    text-align: center;
    margin: 3px;
}

/* Set form to flex */
.create-buy-order-form form {
    display: flex;
    flex-direction: column;
}

/* Filtered Items Box */
.filtered-items-box {
    height: 18em;
    overflow-y: auto;
    background-color: #2f2f3e;
    border: 1px solid #6d4e27;
    padding: 10px;
    border-radius: 5px;
    margin: 5px;
}

.filtered-items-list {
    list-style: none;
    padding: 0;
}

.filtered-items-list li {
    cursor: pointer;
    padding: 5px;
    transition: background-color 0.2s;
}

.filtered-items-list li:hover {
    background-color: #444556;
}

.no-items {
    color: #f8f0c6;
    font-size: 14px;
}

/* Form Inputs */
.selected-item-name,
.search-bar,
input[type="number"] {
    color: #f8f0c6;
    border-radius: 4px;
    background-color: #1c1c29;
    border: 2px solid #6d4e27;
    margin: 3px;
}

.search-bar {
    width: 100%;
    margin-top: 10px;
    margin: 5px;
}

/* Inline Input Container */
.price-quantity-container {
    display: flex;
    gap: 15px;
    margin-top: 10px;
}

.inline-input-container {
    display: flex;
    flex: 1;
}

.inline-input-container label {
    font-weight: 700;
    font-size: 14px;
    color: #ffd700;
}

.inline-input-container input {
    width: 100%;
    padding: 10px;
    color: #f8f0c6;
    border-radius: 4px;
    background-color: #1c1c29;
    border: 2px solid #6d4e27;
    box-sizing: border-box;
}

/* Duration Buttons */
.duration-buttons {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.duration-buttons button {
    flex: 1;
    padding: 10px;
    background-color: #6d4e27;
    border: none;
    color: #f8f0c6;
    cursor: pointer;
    border-radius: 4px;
}

.duration-buttons .active {
    background-color: #8a6a45;
}

/* Create Button */
.create-button {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-family: 'Cinzel', serif;
    color: #f8f0c6;
    background-color: #6d4e27;
    border: 2px solid #6d4e27;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin: 10px 0;
}

.create-button:hover {
    background-color: #8a6a45;
}

/* Right Box */
.right-box {
    grid-column: 2 / 3;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 44em;
}

.top-box, .bottom-box {
    padding: 10px;
    background-color: #1c1c29;
    border-radius: 8px;
    border: 2px solid #6d4e27;
    height: 42%;
}

.top-box h2, .bottom-box h2 {
    margin: 5px;
    color: #ffd700;
}

/* Styled List */
.styled-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 90%;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin: 5px 0;
    background-color: #2f2f3e;
    border-radius: 5px;
    border: 1px solid #6d4e27;
    transition: background-color 0.2s ease;
}

.list-item:hover {
    background-color: #444556;
}

/* Item Details */
.item-name, .item-quantity, .item-price,
.order-quantity, .order-price {
    font-size: 14px;
    color: #f8f0c6;
    font-weight: 500;
}

.item-name {
    flex: 1;
    text-align: left;
}

.item-quantity, .order-quantity {
    flex: 0.5;
    text-align: center;
}

.item-price, .order-price {
    flex: 0.5;
    text-align: right;
}
