:root {
  --background-dark: #1c1c29;
  --text-gold: #ffd700;
  --text-light: #f8f0c6;
  --highlight-text: #ffdd57;
  --border-color: #6d4e27;
  --card-bg: #2f2f3e;
}

.profileContainer {
  padding: 20px;
  margin: auto;
  background-color: var(--background-dark);
  color: var(--highlight-text);
  border-radius: 15px;
  font-family: 'Cinzel', serif;
  border: 2px solid var(--border-color);
}

.profileTitle {
  font-size: 2.5rem;
  text-align: center;
  margin: auto;
  color: var(--text-gold);
}

.profileSection {
  margin-top: 1.5rem;
}

.profileLabel {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--highlight-text);
  font-weight: bold;
}

.statsTable {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1.5rem;
}

.statsTable th,
.statsTable td {
  padding: 10px;
  text-align: center;
  border: 1px solid var(--border-color);
  color: var(--text-light);
}

.statsTable th {
  background-color: var(--card-bg);
  color: var(--highlight-text);
  font-weight: bold;
}

.statsTable tbody tr:nth-child(even) {
  background-color: var(--card-bg);
}

.statsContainer p {
  margin: 0.5rem 0;
  color: var(--text-light);
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.friendRequestIcon {
  color: var(--highlight-text);
  font-size: 1.5rem;
  cursor: pointer;
  position: absolute;
  right: 10px;
  transition: color 0.3s;
}

.friendRequestIcon:hover {
  color: var(--text-gold);
}

.backLink {
  display: inline-block;
  color: var(--highlight-text);
  text-decoration: none;
  font-size: 1.2rem;
  margin-bottom: 15px;
  transition: color 0.3s ease;
}

.backLink:hover {
  color: var(--text-gold);
}





.equippedItemsSection {
  flex: 1;
  background-color: var(--background-dark);
  border: 1px solid #6d4e27;
  border-radius: 8px;
  padding: 20px;
}

.equippedGrid {
  display: grid;
  grid-template-columns: 100px 100px 100px;
  grid-template-rows: auto auto auto auto;
  grid-template-areas:
    ". head ."
    "mainHand torso shoulders"
    "offHand legs hands"
    ". feet .";
  grid-gap: 20px;
  justify-content: center;
}

.equippedSmallItemsGrid {
  display: grid;
  grid-template-columns: repeat(5, 60px);
  grid-gap: 20px;
  justify-content: center;
  margin-top: 20px;
}

.equippedSlot {
  background-color: var(--background-light-dark);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  width: 100px;
  height: 100px;
}

.equippedSlot h3 {
  color: var(--highlight-gold);
  margin: 8px;
  font-size: 14px;
}

.head { grid-area: head; border: 1px solid #6d4e27;}
.torso { grid-area: torso; border: 1px solid #6d4e27;}
.legs { grid-area: legs; border: 1px solid #6d4e27;}
.feet { grid-area: feet; border: 1px solid #6d4e27;}
.mainHand { grid-area: mainHand; border: 1px solid #6d4e27;}
.offHand { grid-area: offHand; border: 1px solid #6d4e27;}
.hands { grid-area: hands; border: 1px solid #6d4e27;}
.shoulders { grid-area: shoulders; border: 1px solid #6d4e27;}

.smallItemSlot {
  width: 60px;
  height: 60px;
}

.cloak, .neck, .amulet, .armring, .ring { grid-area: auto; border: 1px solid #6d4e27;}

.itemIcon {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-bottom: 10px;
  border: 4px solid var(--highlight-text);
  /* box-shadow: 0 0 15px #ffdd57; */
  box-shadow: 0 0 15px #ff000099;
}

.equippedItemsAndGeneralInfoSection {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 20px;
  background-color: var(--background-dark);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 20px;
}

.generalInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.generalInfo p {
  margin: 0.5rem 0;
}