/* Header.module.css */

:root {
  --background-dark: #1c1c29;
  --background-medium: #33394d;
  --text-light: #f8f0c6;
  --highlight: #ffd700;
  --link-color: #ffd700;
  --link-hover-color: #f8f0c6;
  --shadow-color: #6d4e27;
}

/* Style for the entire banner */
.banner {
  background-color: var(--background-dark);
  color: var(--text-light);
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.3rem;
  min-height: 90px;
  box-shadow: 0 4px 2px -2px var(--shadow-color);
  border-bottom: 1px solid #6d4e27;
}

/* Style for the clickable h1 link */
.logo {
  margin: 0;
  font-size: 2rem;
  font-weight: 700;
  color: var(--text-light);
  text-decoration: none;
  transition: color 0.3s ease, text-shadow 0.3s ease;
  cursor: pointer;
}

/* Remove default link styles */
.banner a {
  text-decoration: none;
}

/* Hover effect for h1 link */
.logo:hover {
  color: var(--highlight);
  text-shadow: 2px 2px 4px rgba(255, 215, 0, 0.5); /* Glowing effect */
}

/* Styles for the top-right links container */
.topRightLinks {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  max-width: 200px;
  white-space: normal;
}

/* Style for each link in the top-right section */
.topRightLinks a {
  margin-bottom: 5px;
  font-size: 0.9rem;
  color: var(--link-color);
  text-decoration: none;
  word-wrap: break-word;
}

/* Remove margin for the last link */
.topRightLinks a:last-child {
  margin-bottom: 0;
}

/* Hover effect for top-right links */
.topRightLinks a:hover {
  text-decoration: underline;
  color: var(--link-hover-color);
}

.issueButton {
  background: none;
  color: var(--link-color);
  border: none;
  cursor: pointer;
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.issueButton:hover {
  background-color: #ffffff00;
  text-decoration: underline;
  color: var(--link-hover-color);
}