:root {
    --background-dark: #1c1c29;
    --background-medium: #33394d;
    --text-light: #f8f0c6;
    --highlight: #ffd700;
    --border-color: #6d4e27;
    --link-color: #ffd700;
    --link-hover-color: #f8f0c6;
    --button-primary-bg: #6d4e27;
    --button-primary-hover-bg: #5a3e22;
    --button-danger-bg: #ff4c4c;
    --button-danger-hover-bg: #ff1c1c;
  }

.craftingDistrictContainer {
    color: var(--text-main);
    padding: 20px;
    background-color: var(--background-dark);
    border-radius: 12px;
    border: 2px solid var(--border-color);
}

.craftingTitle {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 20px;
    color: var(--text-gold);
}

.craftingDistrictDescription {
    font-size: 1.1rem;
    color: var(--text-main);
    margin-bottom: 20px;
    line-height: 1.6;
    background-color: var(--card-bg);
    padding: 20px;
    border-radius: 8px;
    border: 1px solid var(--border-color);
    text-align: justify;
}

.professionList, .blueprintList {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    text-align: center;
}

.professionCard, .blueprintCard {
    background-color: var(--card-bg);
    border-radius: 8px;
    padding: 20px;
    width: calc(33.33% - 40px);
    max-width: 250px;
    min-width: 200px;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    text-align: center;
}

.professionCard:hover, .blueprintCard:hover {
    transform: scale(1.05);
}

.professionName, .blueprintName {
    margin: 0;
    color: var(--text-gold);
}

.backButton, .craftButton {
    background-color: var(--border-color);
    color: var(--text-main);
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.backButton:hover, .craftButton:hover {
    background-color: var(--button-hover-bg);
}

.selectedProfession, .selectedBlueprint {
    background-color: var(--card-bg);
    padding: 20px;
    border-radius: 12px;
    border: 1px solid var(--border-color);
    margin-top: 20px;
}

.blueprintDescription {
    color: var(--highlight-text);
    margin-top: 10px;
}

.blueprintTable th,
.blueprintTable td {
    padding: 10px;
    border: 1px solid var(--border-color);
    text-align: left;
}

.blueprintTable th {
    background-color: var(--background-medium);
    color: var(--highlight);
    font-weight: bold;
}

.blueprintRow:hover {
    background-color: var(--background-medium);
    cursor: pointer;
}

.blueprintRow ul {
    margin: 0;
}

.itemIcon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.componentsList {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.componentItem {
    display: flex;
    align-items: center;
    width: 100%;
}

.componentIcon {
    width: 24px;
    height: 24px;
    margin-right: 5px;
}