.tooltip {
  position: absolute;
  background: var(--background-dark);
  color: var(--highlight-text);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 15px;
  z-index: 1000;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  pointer-events: none;
  width: 300px;
  min-height: 150px;
  max-width: 100%;
  overflow: hidden;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  text-align: left;
}

.tooltip-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;
}

.tooltip-header h3 {
  margin: 0;
  font-size: 18px;
  color: var(--text-gold);
  flex: 1;
}

.tooltip-image {
  width: 60px;
  height: 60px;
  object-fit: contain;
  margin-left: 10px;
}

.tooltip-body {
  text-align: left;
}

.tooltip-body p {
  margin: 5px 0;
  font-size: 15px;
  color: var(--highlight-text);
  text-align: left;
}

.tooltip-body p span {
  font-size: 15px;
}

.tooltip-requirements {
  margin-top: 10px;
}

.tooltip-requirements p {
  font-size: 14px;
  color: var(--highlight-text);
  text-align: left;
}

.tooltip p strong {
  color: var(--text-gold);
}
