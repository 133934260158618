.browse-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.header-bar {
    display: flex;
    font-weight: bold;
    border-bottom: 2px solid #6d4e27;
}

.header-bar .header-column,
.item-row .item-column {
    text-align: center;
}

.header-column.name-column,
.item-column.name-column {
    width: 60%;
    text-align: left;
}

.header-column.expires-column,
.item-column.expires-column {
    width: 10%;
}

.header-column.qty-column,
.item-column.qty-column {
    width: 15%;
}

.header-column.price-column,
.item-column.price-column {
    width: 10%;
}

.header-column.action-column,
.item-column.action-column {
    width: 5%;
    text-align: center;
}

.item-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.item-row {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #6d4e27;
}

.item-row:hover {
    background-color: #2f2f3e;
    border-radius: 3px;
}

.item-container {
    display: flex;
    align-items: center;
}

.item-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px; 
    object-fit: contain;
    vertical-align: middle;
    align-items: center;
}

.item-text {
    font-size: 18px;
    line-height: 24px;
    color: #f8f0c6;
    align-items: center;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    padding-bottom: 10px;
}

.page-button {
    margin: 0 5px;
    padding: 5px 10px;
    cursor: pointer;
    background-color: rgba(42, 58, 74, 0.85);
    color: #f8f0c6;
    border: 1px solid #6d4e27;
    border-radius: 5px;
}

.page-button.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.auction-buy-btn {
    padding: 3px;
    cursor: pointer;
    background-color: #174716;
    color: #ffd700;
    border: 2px solid #ffd700;
    border-radius: 16px;
    font-family: Uncial Antiqua, serif;
    font-size: 12px;
    transition: background-color 0.3s;
    width: 60px;
}

.auction-buy-btn:hover {
    background-color: #8a6a45;
}

.quantity-input {
    width: 40px;
    text-align: center;
    border: 1px solid #6d4e27;
    border-radius: 4px;
    padding: 3px;
    font-size: 0.9rem;
    background-color: #1c1c29;
    color: #f8f0c6;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* For Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}