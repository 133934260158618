.accountContainer {
  max-width: 600px;
  margin: 0 auto;
  padding: 30px;
  background-color: #1c1c29;
  color: #f8f0c6;
  border-radius: 12px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  font-family: 'Cinzel', serif;
  border: 2px solid #6d4e27;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #ffd700;
  font-size: 2rem;
}

.settingsSection {
  margin-bottom: 40px;
  padding: 10px;
}

.formGroup {
  margin-bottom: 20px;
}

.formGroup label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #ffd700;
  font-size: 14px;
}

.formGroup input {
  width: 100%;
  padding: 12px;
  border: 1px solid #666;
  border-radius: 5px;
  background-color: #2f2f3e;
  color: #f8f0c6;
  font-family: 'Arial', sans-serif;
  transition: border-color 0.3s ease;
}

.formGroup input:focus {
  border-color: #ffd700;
  outline: none;
  background-color: #3a3b47;
}

.updateButton {
  background-color: #6d4e27;
  color: #f8f0c6;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-family: 'Cinzel', serif;
  font-weight: bold;
}

.updateButton:hover {
  background-color: #8a6a45;
  transform: scale(1.05);
}

.successMessage {
  color: #28a745;
  text-align: center;
  margin-bottom: 20px;
}

.errorMessage {
  color: #dc3545;
  text-align: center;
  margin-bottom: 20px;
}
