@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;700&family=MedievalSharp&display=swap');

.tournamentDetailContainer {
  padding: 25px;
  background-color: #1c1c29;
  color: #f8f0c6;
  border-radius: 12px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  border: 2px solid #6d4e27;
  font-family: 'Cinzel', serif;
  overflow-y: auto;
  max-height: 90vh;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.tournamentDetailContainer::-webkit-scrollbar {
  display: none;
}

.title {
  font-family: 'MedievalSharp', serif;
  color: #ffd700;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
}

.surrenderAtSelect,
.strategySelect {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  background-color: #2a2a3b;
  border: 1px solid #5a5a5a;
  border-radius: 5px;
  color: #ffd700;
}

.joinButton,
.leaveButton {
  width: 100%;
  padding: 12px;
  font-weight: bold;
  border-radius: 5px;
  color: #f8f0c6;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 10px;
}

.joinButton {
  background-color: #006400;
}

.leaveButton {
  background-color: #8b0000;
}

.joinButton:hover {
  background-color: #228b22;
}

.leaveButton:hover {
  background-color: #a83232;
}

@media (max-width: 768px) {
  .tournamentDetailContainer {
    padding: 20px;
  }

  .title {
    font-size: 1.5rem;
  }
}
