:root {
  --background-dark: #1c1c29;
  --text-gold: #ffd700;
  --text-light: #f8f0c6;
  --border-color: #6d4e27;
  --highlight-text: #ffdd57;
  --chat-bg: #2f2f3e;
  --button-danger: #ee4141;
  --button-danger-hover: #db3939;
  --button-success: #43b581;
  --button-success-hover: #36a371;
  --button-back: #4867d8;
  --button-back-hover: #2642a8;
}

.lobbyViewContainer {
  padding: 20px;
  background-color: var(--background-dark);
  color: var(--text-gold);
  border-radius: 15px;
  margin: auto;
  border: 2px solid var(--border-color);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  font-family: 'Cinzel', serif;
}

.lobbyTitle {
  font-family: 'MedievalSharp', serif;
  text-align: center;
  font-size: 2.5rem;
  color: var(--text-gold);
  margin-bottom: 20px;
}

.lobbyInfo {
  text-align: center;
  font-size: 1.2rem;
  color: var(--text-light);
  margin-bottom: 20px;
}

.teams {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.team {
  width: 45%;
  background-color: var(--chat-bg);
  padding: 20px;
  border-radius: 8px;
  border: 1px solid var(--border-color);
}

.team h3 {
  text-align: center;
  color: var(--text-gold);
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.participantItem {
  background-color: #333;
  padding: 10px 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Cinzel', serif;
}

.kickButton {
  background: none;
  border: none;
  color: #ff5555;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.kickButton:hover {
  transform: scale(1.1);
}

.chatContainer {
  background-color: var(--chat-bg);
  padding: 15px;
  border-radius: 10px;
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid var(--border-color);
}

.chatMessages {
  overflow-y: auto;
  max-height: 300px;
  padding: 10px;
  border-radius: 5px;
  scrollbar-width: none;
}

.chatMessages::-webkit-scrollbar {
  display: none;
}

.chatMessage {
  background-color: #333;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  font-family: 'Cinzel', serif;
}

.chatTime {
  color: #999;
  margin-right: 10px;
}

.chatUsername {
  font-weight: bold;
  color: var(--text-gold);
  margin-right: 5px;
}

.chatInput {
  display: flex;
  margin-top: 10px;
}

.chatInput input {
  flex-grow: 1;
  padding: 10px;
  border-radius: 5px 0 0 5px;
  border: none;
  background-color: #444;
  color: var(--text-gold);
}

.chatInput button {
  background-color: var(--text-gold);
  border: none;
  padding: 10px;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.chatInput button:hover {
  background-color: #e6c200;
}

.actionButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.actionButton {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.deleteLobbyButton {
  background-color: var(--button-danger);
  color: #f8f0c6;
}

.deleteLobbyButton:hover {
  background-color: var(--button-danger-hover);
}

.leaveButton {
  background-color: var(--text-gold);
  color: black;
}

.leaveButton:hover {
  background-color: #e6c200;
}

.backButton {
  background-color: var(--button-back);
  color: white;
}

.backButton:hover {
  background-color: var(--button-back-hover);
}

.startBattleButton {
  background-color: var(--button-success);
  color: white;
}

.startBattleButton:hover {
  background-color: var(--button-success-hover);
}

/* Responsive Design */
@media (max-width: 768px) {
  .teams {
    flex-direction: column;
  }

  .team {
    width: 100%;
    margin-bottom: 20px;
  }
}

.randomLobby {
  background-color: var(--chat-bg);
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 30px;
}

.participantList {
  list-style: none;
  padding: 0;
}

.participantItem {
  background-color: #333;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}
