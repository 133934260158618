.vaultPage {
    padding: 20px;
    background-color: #1c1c29;
    color: #ffd700;
    font-family: 'Cinzel', serif;
    border: 2px solid #6d4e27;
    border-radius: 10px;
  }
  
  .title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .contentContainer {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .section {
    width: 30%;
  }
  
  .section h2 {
    border-bottom: 1px solid #ffd700;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
  
  .actionContainer {
    text-align: center;
  }
  
  .inputGroup {
    margin-bottom: 10px;
  }
  
  .inputGroup label {
    display: block;
    margin-bottom: 5px;
  }
  
  .inputGroup input,
  .inputGroup select {
    width: 50%;
    max-width: 300px;
    padding: 5px;
    border: 1px solid #ffd700;
    border-radius: 5px;
  }
  
  .buttonGroup button {
    margin: 5px;
    padding: 10px 20px;
    background-color: #6d4e27;
    color: #ffd700;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s;
  }
  
  .buttonGroup button:hover {
    background-color: #aa7d4e;
  }
  
.vaultActions input[type='number'],
.vaultActions select {
  width: 100%;
  max-width: 300px;
  padding: 10px;
  border: 2px solid #ffd700;
  background-color: #1c1c29;
  color: #ffd700;
  font-family: 'Cinzel', serif;
  border-radius: 5px;
  margin-bottom: 10px;
  text-align: center;
}

.vaultActions button {
  padding: 10px 20px;
  margin: 5px;
  font-family: 'Cinzel', serif;
  background-color: #6d4e27;
  color: #ffd700;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.vaultActions button:hover {
  background-color: #8b5e3c;
}

.inputGroup .itemQuantity {
    width: 40px;
}