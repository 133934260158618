:root {
  --background-dark: #1c1c29;
  --text-gold: #ffd700;
  --text-light: #f8f0c6;
  --highlight-text: #ffdd57;
  --error-color: #ff6b6b;
  --button-primary-bg: #6d4e27;
  --button-disabled-bg: #555;
  --border-color: #6d4e27;
  --card-bg: #2f2f3e;
  --card-hover-shadow: 0 0 15px #ffd700;
}

.rankingContainer {
  padding: 20px;
  margin: auto;
  background-color: var(--background-dark);
  color: var(--text-light);
  border-radius: 15px;
  font-family: 'Cinzel', serif;
  border: 2px solid var(--border-color);
}

.rankingTitle {
  font-size: 2rem;
  text-align: center;
  color: var(--text-gold);
  margin-bottom: 1.5rem;
}

.errorMessage {
  color: var(--error-color);
  text-align: center;
  font-weight: bold;
}

.rankingTable {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.rankingTable th,
.rankingTable td {
  padding: 10px;
  text-align: center;
  border: 1px solid var(--border-color);
  color: var(--text-light);
}

.rankingTable th {
  background-color: var(--card-bg);
  color: var(--highlight-text);
  font-weight: bold;
}

.rankingTable tr:nth-child(even) {
  background-color: var(--card-bg);
}

.paginationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.paginationButton {
  background-color: var(--text-gold);
  color: var(--background-dark);
  padding: 8px 12px;
  margin: 0 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.paginationButton:hover:not(:disabled) {
  background-color: var(--highlight-text);
  box-shadow: var(--card-hover-shadow);
}

.paginationButton:disabled {
  background-color: var(--button-disabled-bg);
  cursor: not-allowed;
}

.paginationInfo {
  margin: 0 10px;
  color: var(--highlight-text);
  font-weight: bold;
}

.profileLink {
  color: var(--highlight-text);
  text-decoration: none;
  font-weight: bold;
  transition: text-decoration 0.3s ease;
}

.profileLink:hover {
  text-decoration: underline;
}
