/* ChapterView.css */
.chapter-view {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #2d2d2d;
    color: #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
    font-family: 'Georgia', serif;
  }
  
  .chapter-text {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 20px;
    color: #c5c5c5;
    padding: 10px 0;
    border-bottom: 1px solid #444;
  }
  
  .end-message {
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    color: #ffa726;
  }
  
  .choices {
    list-style: none;
    padding: 0;
    margin-top: 20px;
  }
  
  .choice-item {
    cursor: pointer;
    font-size: 1.1rem;
    padding: 12px;
    margin: 8px 0;
    background-color: #3e3e3e;
    border: 1px solid #444;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .choice-item:hover {
    background-color: #ffa726;
    color: #1d1d1d;
  }
  
  .choice-item:active {
    background-color: #ff8f00;
    color: #2d2d2d;
  }
  