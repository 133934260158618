.emojiPicker {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    padding: 10px;
  }
  
  .emoji {
    width: 48px;
    height: 48px;
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .emoji:hover {
    transform: scale(1.2);
  }

.emojiToggleButton,
.sendButton {
margin-left: 10px;
padding: 10px;
color: #ffffff;
background-color: #8b4513;
border: none;
border-radius: 5px;
cursor: pointer;
font-weight: bold;
transition: background-color 0.3s ease;
}

.emojiToggleButton:hover,
.sendButton:hover {
background-color: #6b2e0b;
}
  
.emojiPickerOverlay {
    position: absolute;
    bottom: 60px;
    left: 10px;
    background-color: rgba(46, 46, 72, 0.95);
    border: 1px solid #3e3e5e;
    border-radius: 5px;
    padding: 10px;
    z-index: 100;
    max-width: 300px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  }